import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { id: "nav" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

import { ElMenu } from 'element-plus'
import batchPay from './batch-pay.vue'

import { defineComponent } from 'vue'
import { getMenu, UserMenuDto } from '@/api/base'
import user from '../utils/user'
import 'shepherd.js/dist/css/shepherd.css'
import { useShepherd } from 'vue-shepherd'
import { offset } from '@floating-ui/dom'
const __default__ = defineComponent({
    props: ['active', 'list'],
    data() {
        return {
            isBatchPay: false,
            menu: [] as UserMenuDto[],
            accountExpireInfoStatus: 0,
            tour: null as any,
            defaultOpenIndex: '0',
            defaultActive: user.isPcAdmin ? '/' : '/pay'
        }
    },
    computed: {
        imgUrl() {
            return (name: string) => {
                try {
                    return require(`@/assets/menu/${name}.png`)
                } catch (error) {
                    return ''
                }
            }
        }
    },
    async mounted() {
        const menu = await getMenu()
        this.menu = menu

        this.defaultActive = menu?.[0]?.children?.[0].path ?? '/'

        console.log(this.defaultActive, 'this.defaultActive')

        this.handleStart()
        // this.menu.push({
        //     "name": "",
        //     "path": "",
        //     "hidden": false,
        //     "redirect": "noRedirect",
        //     "component": "Layout",
        //     "alwaysShow": true,
        //     "meta": {
        //         "title": "预支",
        //         "icon": "el-icon-s-help",
        //         "noCache": false,
        //         "remark": ""
        //     },
        //     "children": [
        //         {
        //             "name": "AdvanceApplay",
        //             "path": "/advance/applay",
        //             "hidden": false,
        //             "component": "ParentView",
        //             "meta": {
        //                 "title": "预支申请单",
        //                 "icon": "el-icon-plus",
        //                 "noCache": false,
        //                 "remark": ""
        //             }
        //         },
        //         {
        //             "name": "AdvanceSetting",
        //             "path": "/advance/setting",
        //             "hidden": false,
        //             "component": "ParentView",
        //             "meta": {
        //                 "title": "预支设置",
        //                 "icon": "el-icon-plus",
        //                 "noCache": false,
        //                 "remark": ""
        //             }
        //         },
        //     ]
        // })
        user.waitLogin().then(() => {
            this.accountExpireInfoStatus = user.accountExpireInfoStatus
            console.log(this.accountExpireInfoStatus, user.accountExpireInfoStatus, '首页状态')
        })
    },
    methods: {
        async goRouter(path: string, ev: any) {
            this.menu.forEach(i => {
                if (i?.meta?.icon) {
                    i.meta.icon = i.meta.icon.replace(/-active$/, '')
                }
            })
            ev.meta.icon = ev.meta.icon.includes('active') ? ev.meta.icon : ev.meta.icon + '-active'
            console.log(path, 'goRoutergoRouter')
            // store.commit('setNavStatus', to.meta.isNav !== false)
            const res = await this.$router.push({
                path
            })
        },
        createStep() {
            let _this = this
            setTimeout(() => {
                this.tour = useShepherd({
                    useModalOverlay: true,
                    keyboardNavigation: true,
                    defaultStepOptions: {
                        // cancelIcon: {
                        //     enabled: true
                        // },
                        scrollTo: { behavior: 'smooth', block: 'center' },
                        // 高亮元素四周要填充的空白像素
                        modalOverlayOpeningPadding: 8,
                        // 空白像素的圆角
                        modalOverlayOpeningRadius: 4,
                        buttons: [{
                            action() {
                                return this.back()
                            },
                            text: '上一步'
                        }, {
                            action() {
                                return this.next()
                            },
                            text: '下一步'
                        }] as any
                    }
                })
                const lists = Array.from(document.querySelectorAll('.el-scrollbar__view>.el-menu>li'))
                console.log(11111, lists)
                const cur = lists.findIndex((i: any) => {
                    return i.innerHTML.includes('预支管理')
                })
                console.log(22222, cur)
                this.tour.addStep({
                    attachTo: { element: lists[cur], on: 'right' },
                    text: '"预支工资"已经开放使用，快来体验吧~',
                    classes: 'example-step-extra-class',
                    floatingUIOptions: {
                        middleware: [offset({ mainAxis: 30, crossAxis: 12 })]
                    },
                    buttons: [
                        {
                            text: '跳过',
                            classes: 'shepherd-btn1',
                            action() {
                                _this.handleFinish()
                                return this.complete()
                            }
                        },
                        {
                            text: '下一步1/6',
                            classes: 'shepherd-btn2',
                            action() {
                                const index = _this.menu.findIndex((i: any) => {
                                    return i.meta.title === '预支管理'
                                })
                                if (index !== -1) {
                                    console.log((_this as any).$refs)
                                    ; (_this as any).$router.push('/advance/setting')
                                        ; (_this as any).$refs.menu.open(index)
                                }
                                return this.next()
                            }
                        }
                    ] as any
                })
                const lists2 = Array.from(document.querySelectorAll('.el-menu-item'))
                const cur2 = lists2.findIndex((i: any) => {
                    return i.innerHTML.includes('预支配置')
                })
                this.tour.addStep({
                    attachTo: { element: lists2[cur2], on: 'right' },
                    text: '进入页面，设置预支规则和配置企业',
                    classes: 'example-step-extra-class',
                    floatingUIOptions: {
                        middleware: [offset({ mainAxis: 30, crossAxis: 12 })]
                    },
                    buttons: [
                        {
                            text: '跳过',
                            classes: 'shepherd-btn1',
                            action() {
                                _this.handleFinish()
                                return this.complete()
                            }
                        },
                        {
                            text: '下一步2/6',
                            classes: 'shepherd-btn2',
                            action() {
                                return this.next()
                            }
                        }
                    ] as any
                })
                this.tour.addStep({
                    attachTo: {
 element: function() {
                        return document.querySelector('#advanceRule')
                    },
on: 'bottom'
},
                    text: '在这里设置预支规则',
                    classes: 'example-step-extra-class',
                    beforeShowPromise: () => {
                        new Promise((resolve: any) => {
                            resolve(document.querySelector('#advanceRule'))
                        })
                    },
                    floatingUIOptions: {
                        middleware: [offset({ mainAxis: 30, crossAxis: 12 })]
                    },
                    buttons: [
                        {
                            text: '跳过',
                            classes: 'shepherd-btn1',
                            action() {
                                _this.handleFinish()
                                return this.complete()
                            }
                        },
                        {
                            text: '下一步3/6',
                            classes: 'shepherd-btn2',
                            action() {
                                return this.next()
                            }
                        }
                    ] as any
                })

                this.tour.addStep({
                    attachTo: {
 element: function() {
                        return document.querySelector('#setCompany')
                    },
on: 'bottom'
},
                    text: '在这里配置预支企业，在预支列表中的企业，工人可申请预支',
                    classes: 'example-step-extra-class',
                    floatingUIOptions: {
                        middleware: [offset({ mainAxis: 30, crossAxis: 12 })]
                    },
                    buttons: [
                        {
                            text: '跳过',
                            classes: 'shepherd-btn1',
                            action() {
                                _this.handleFinish()
                                return this.complete()
                            }
                        },
                        {
                            text: '下一步4/6',
                            classes: 'shepherd-btn2',
                            action() {
                                const index = _this.menu.findIndex((i: any) => {
                                    return i.meta.title === '财务对账'
                                })
                                if (index !== -1) {
                                    console.log((_this as any).$refs)
                                    ; (_this as any).$router.push('/bill')
                                        ; (_this as any).$refs.menu.open(index)
                                }
                                return this.next()
                            }
                        }
                    ] as any
                })

                const cur3 = lists.findIndex((i: any) => {
                    return i.innerHTML.includes('财务对账')
                })

                this.tour.addStep({
                    attachTo: { element: lists[cur3], on: 'right' },
                    text: '"对账功能" 移动到这里了',
                    classes: 'example-step-extra-class',
                    floatingUIOptions: {
                        middleware: [offset({ mainAxis: 30, crossAxis: 12 })]
                    },
                    buttons: [
                        {
                            text: '跳过',
                            classes: 'shepherd-btn1',
                            action() {
                                _this.handleFinish()
                                return this.complete()
                            }
                        },
                        {
                            text: '下一步5/6',
                            classes: 'shepherd-btn2',
                            action() {
                                return this.next()
                            }
                        }
                    ] as any
                })

                this.tour.addStep({
                    attachTo: {
 element: function() {
                        return document.querySelector('#goCheck')
                    },
on: 'bottom'
},
                    text: '导入员工这个月的薪资标准，系统自动帮你进行核算。 ',
                    classes: 'example-step-extra-class',
                    floatingUIOptions: {
                        middleware: [offset({ mainAxis: 30, crossAxis: 12 })]
                    },
                    buttons: [
                        {
                            text: '跳过',
                            classes: 'shepherd-btn1',
                            action() {
                                _this.handleFinish()
                                return this.complete()
                            }
                        },
                        {
                            text: '结束6/6',
                            classes: 'shepherd-btn2',
                            action() {
                                _this.handleFinish()
                                return this.complete()
                            }
                        }
                    ] as any
                })

                this.tour.start()
            }, 100)
        },
        handleStart() {
            // 如果没有完成指导开始做指导操作
            if (!localStorage.getItem('guide-advance-finish')) {
                this.createStep()
            }
        },
        handleFinish() {
            this.$router.push('/')
            localStorage.setItem('guide-advance-finish', 'true')
        }
    }
})


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'container-nav',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_scrollbar, { class: "nav" }, {
      default: _withCtx(() => [
        _cache[2] || (_cache[2] = _createElementVNode("img", {
          class: "nav-logo",
          src: _imports_0
        }, null, -1)),
        _createElementVNode("button", {
          class: _normalizeClass([_ctx.accountExpireInfoStatus === 3 ? 'btn-active' : '', 'btn']),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isBatchPay = true)),
          disabled: _ctx.accountExpireInfoStatus === 3
        }, "批量发薪", 10, _hoisted_2),
        _createVNode(_unref(ElMenu), {
          ref: "menu",
          "active-text-color": "#1989FA",
          "text-color": "#202536",
          "default-active": _ctx.defaultActive,
          "default-openeds": [_ctx.defaultOpenIndex],
          mode: "vertical"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (i, k) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: k }, [
                (!i.children)
                  ? (_openBlock(), _createBlock(_component_el_menu_item, {
                      key: 0,
                      onClick: ($event: any) => (_ctx.goRouter(i.path, i)),
                      index: i.path
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          class: "icon-nav",
                          src: _ctx.imgUrl(i.meta.icon),
                          alt: ""
                        }, null, 8, _hoisted_3),
                        _createElementVNode("span", null, _toDisplayString(i.meta.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick", "index"]))
                  : (_openBlock(), _createBlock(_component_el_sub_menu, {
                      key: 1,
                      index: i.path === '/' ? i.meta?.title : i.path
                    }, {
                      title: _withCtx(() => [
                        _createElementVNode("img", {
                          class: "icon-nav",
                          src: _ctx.imgUrl(i.meta.icon),
                          alt: ""
                        }, null, 8, _hoisted_4),
                        _createElementVNode("span", {
                          class: _normalizeClass({'active': i.meta?.icon?.includes('active')})
                        }, _toDisplayString(i.meta.title), 3)
                      ]),
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.children, (item, index) => {
                          return (_openBlock(), _createBlock(_component_el_menu_item, {
                            key: index,
                            index: item.path,
                            onClick: ($event: any) => (_ctx.goRouter(item.path, i))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.meta.title), 1)
                            ]),
                            _: 2
                          }, 1032, ["index", "onClick"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["index"]))
              ], 64))
            }), 128))
          ]),
          _: 1
        }, 8, ["default-active", "default-openeds"])
      ]),
      _: 1
    }),
    (_ctx.isBatchPay)
      ? (_openBlock(), _createBlock(batchPay, {
          key: 0,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isBatchPay = false)),
          isType: "pay"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})