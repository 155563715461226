import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, createTextVNode as _createTextVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/xlsx/success.png'


const _hoisted_1 = { class: "process" }
const _hoisted_2 = {
  key: 0,
  class: "box-block form"
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  class: "value",
  style: {"display":"flex","align-items":"center","flex":"auto"}
}
const _hoisted_6 = { style: {"color":"#1989fa"} }
const _hoisted_7 = { style: {"color":"#1989fa"} }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "value" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "value" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "value" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "value" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "value" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "value" }
const _hoisted_23 = {
  key: 1,
  class: "form-error"
}
const _hoisted_24 = { class: "error-table" }
const _hoisted_25 = {
  key: 2,
  class: "box-block enclosure"
}
const _hoisted_26 = { class: "form-left" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { key: 1 }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { class: "form-right" }
const _hoisted_33 = { class: "info-enclosure-name parallel" }
const _hoisted_34 = { class: "info-right" }
const _hoisted_35 = { class: "info-name parallel" }
const _hoisted_36 = { class: "info-right" }
const _hoisted_37 = { class: "info-month parallel" }
const _hoisted_38 = { class: "info-right" }
const _hoisted_39 = { class: "info-pay-enterprise parallel" }
const _hoisted_40 = { class: "info-right" }
const _hoisted_41 = { class: "parallel" }
const _hoisted_42 = { class: "info-right" }
const _hoisted_43 = { class: "info-people-num parallel" }
const _hoisted_44 = { class: "info-right" }
const _hoisted_45 = { class: "info-total-money parallel" }
const _hoisted_46 = { class: "info-right info-right-num" }
const _hoisted_47 = {
  class: "parallel",
  style: {"justify-content":"space-between"}
}
const _hoisted_48 = {
  key: 3,
  class: "box-block result"
}
const _hoisted_49 = { class: "result-info" }
const _hoisted_50 = { class: "info-enclosure-name parallel" }
const _hoisted_51 = { class: "info-right" }
const _hoisted_52 = { class: "info-name parallel" }
const _hoisted_53 = { class: "info-right" }
const _hoisted_54 = { class: "info-month parallel" }
const _hoisted_55 = { class: "info-right" }
const _hoisted_56 = { class: "info-pay-enterprise parallel" }
const _hoisted_57 = { class: "info-right" }
const _hoisted_58 = { class: "parallel" }
const _hoisted_59 = { class: "info-right" }
const _hoisted_60 = { class: "info-people-num parallel" }
const _hoisted_61 = { class: "info-right" }
const _hoisted_62 = { class: "info-total-money parallel" }
const _hoisted_63 = { class: "info-right info-right-num" }

import alertBox from './alert-box.vue'

import { defineComponent } from 'vue'
import {
    getEnterpriseNameList,
    EnterpriseDto,
    getResidentNameList
} from '@/api/enterprise'
import global from '@/utils/global'
import { postBatchPay } from '@/api/pay'
import { ElScrollbar } from 'element-plus'
import { formTesting } from '@/utils/util'
const __default__ = defineComponent({
    created() {
        this.getEnterpriseNameList()
        this.getResidentNameList()
    },
    data() {
        return {
            uploadAction:
                process.env.VUE_APP_BASE_URL +
                '/rest/pay/processOrder/importDataByIdCard', // 导入的路径
            whichStep: 0,
            processaName: '', // 流程名称
            enterpriseIndex: '' as string | number, // 派遣单位名称
            enterpriseName: '', // 派遣单位名称
            enterpriseId: '', // 派遣单位Id
            contactResident: [] as string[], // 联系驻场
            linkMan: [] as string[],
            remarks: '', // 备注
            totalNumber: '', // 总人数
            totalAmount: '' as number | string, // 总金额
            enterpriseList: [] as any, // 派遣单位列表
            esidentList: [] as {
                userName: string;
                userId: string;
                phone: string;
            }[], // 驻场列表
            errorList: [] as {
                idCard: string;
                orderNum: number;
                userName: string;
            }[], // 错误数据列表
            flowCode: '',
            fileName: '' as string,
            userList: [] as {
                id?: number | string;
                idCard: string;
                totalMoney: number | string;
                userName: string;
            }[],
            userListTotal: 0,
            isOnBtn: true,
            fileSize: '',
            dom: '',
            pageNum: 1,
            pageSize: 100,
            fileContentList: [] as {
                id?: number | string;
                idCard: string;
                isError?: boolean;
                totalMoney: number | string;
                userName: string;
            }[],
            editId: '',
            userIndex: 0
        }
    },
    methods: {
        tableRowClassName(row: { row: { isError: boolean } }) {
            console.log(row.row.isError)
            if (row.row.isError) {
                return 'warm-row'
            }
            return ''
        },
        // 上传文件编辑
        onFileEdit(event: string) {
            if (this.editId !== '' && this.editId !== event) {
                this.$message.error('请先保存')
                return
            }
            if (this.editId === event) {
                this.editId = ''
                const userInfo = this.fileContentList.filter(
                    (item: { id?: number | string }) => item.id === event
                )
                this.userList.forEach((item, index) => {
                    // 拿到修改
                    if (item.idCard === userInfo[0].idCard) {
                        this.userIndex = index
                    }
                })
                // 赋值到主列表
                this.userList[this.userIndex].userName = userInfo[0].userName
                this.userList[this.userIndex].idCard = userInfo[0].idCard
                this.userList[this.userIndex].totalMoney =
                    userInfo[0].totalMoney
                // this.totalAmount
                this.totalAmount = 0
                this.userList.forEach(item => {
                    // 总金额重新计算
                    (this.totalAmount as number) += Number(item.totalMoney)
                })
            } else {
                this.editId = event
            }
        },
        handleCurrentChange(event: number) {
            this.pageNum = event
            this.fileContentList = this.userList.slice(
                (this.pageNum - 1) * 100,
                this.pageNum * 100
            )
            console.log(this.fileContentList, 'fileContentList')
        },
        // 查询公司下派遣单位列表
        async getEnterpriseNameList() {
            const result = await getEnterpriseNameList()
            this.enterpriseList = result.list
        },
        async getResidentNameList() {
            const result = await getResidentNameList()
            this.esidentList = result
        },
        onEnterprise(e: number) {
            let obj = this.enterpriseList[e]
            if (obj.isBlacklist || obj.isForbidden) {
                this.enterpriseIndex = ''
            } else {
                this.enterpriseId = this.enterpriseList[
                    this.enterpriseIndex as number
                ].id
                this.enterpriseName = this.enterpriseList[
                    this.enterpriseIndex as number
                ].serveCompanyName
                console.log('enterpriseId', this.enterpriseId)
                this.onLoseBlur()
            }
        },
        residentDropdown(name: string) {
            console.log(name, 'name')
            const index = this.contactResident.indexOf(name)
            console.log(index, 'index')
            if (index === -1) {
                this.contactResident.push(name)
            } else {
                this.contactResident.splice(index, 1)
            }
            console.log(index, 'index')
            console.log(this.contactResident, 'contactResident')
        },
        onUploadFileNext() {
            if (/\s+/.test(this.processaName)) {
                this.$message.warning('流程名称不可输入多个空格')
                return
            }
            if (!this.processaName) {
                this.$message.warning('请输入流程名称')
                return
            }
            if (!this.remarks) {
                this.$message.warning('请输入备注')
                return
            }
            if (!this.enterpriseName) {
                this.$message.warning('请选择派遣单位名称')
                return
            }
            if (this.contactResident.length === 0) {
                this.$message.warning('请选择驻场')
                return
            }
            if (!this.totalNumber) {
                this.$message.warning('请导入文件')
                return
            }
            console.log(this.contactResident, 'contactResident')
            this.whichStep = 1
        },
        fileSuccess(
            response: {
                code: number;
                msg: string;
                data: {
                    flowCode: string;
                    totalMoney: number;
                    totalPerson: string;
                    userList: {
                        id?: number;
                        idCard: string;
                        isError?: boolean;
                        totalMoney: number | string;
                        userName: string;
                    }[];
                    errorUserList: {
                        idCard: string;
                        orderNum: number;
                        userName: string;
                    }[];
                };
            },
            file: { raw: { name: string; size: string } }
        ) {
            if (response.code === 500) {
                this.$message.error(response.msg)
                return
            }
            this.fileSize = (Number(file.raw.size) / 1024).toFixed(1) + 'kb'
            this.fileName = file.raw.name
            this.flowCode = response.data.flowCode
            this.totalAmount = response.data.totalMoney
            this.totalNumber = response.data.totalPerson
            // userList加字段
            // 数据分割
            this.fileContentList = response.data.userList.slice(0, 100)
            this.errorList = response.data.errorUserList
            console.log(this.fileContentList, 'fileContentList')
            response.data.userList.forEach((item, index) => {
                item.id = index
            })
            this.userList = response.data.userList
            this.userListTotal = response.data.userList.length / 100
            this.$message.success('上传成功')
            this.onLoseBlur()
        },
        fileEerror() {
            this.$message.error('文件上传失败')
        },
        async onAddBatchPay() {
            await postBatchPay({
                flowCode: this.flowCode,
                linkman: this.linkMan,
                projectName: this.processaName,
                remarks: this.remarks,
                serveCompanyId: this.enterpriseId,
                serveCompanyName: this.enterpriseName,
                totalMoney: this.totalAmount as number,
                totalPerson: this.totalNumber,
                userList: this.userList
            })
            this.whichStep += 1
        },
        onCloseEject() {
            this.$emit('close')
            this.$emit('complete')
        },
        onLoseBlur() {
            if (
                this.processaName &&
                this.enterpriseName &&
                this.remarks &&
                this.contactResident &&
                this.totalNumber &&
                !this.errorList.length
            ) {
                this.isOnBtn = false
            } else {
                this.isOnBtn = true
            }
        },
        onRemoveTag(event: any) {
            const index = this.contactResident.indexOf(
                this.esidentList.filter(item => item.userId === event)[0]
                    .userName
            )
            this.contactResident.splice(index, 1)
            console.log(index, 'index')
            console.log(event, 'event')
        },
        onDeleteFile() {
            this.totalAmount = 0
            this.totalNumber = ''
            this.fileSize = ''
            this.fileName = ''
            this.$message.success('删除成功')
        }
    }
})


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'batch-pay',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createBlock(alertBox, {
    "data-title": "批量发薪",
    isCloseType: true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.whichStep === 0 ? 'active' : '', 'process-box'])
        }, _cache[10] || (_cache[10] = [
          _createElementVNode("div", { class: "no" }, [
            _createElementVNode("i", { class: "el-icon el-icon-check" })
          ], -1),
          _createElementVNode("span", null, "上传文件", -1)
        ]), 2),
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "arrow" }, [
          _createElementVNode("i", { class: "el-icon el-icon-arrow-right" })
        ], -1)),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.whichStep === 1 ? 'active' : '', 'process-box'])
        }, _cache[11] || (_cache[11] = [
          _createElementVNode("div", { class: "no" }, " 2 ", -1),
          _createElementVNode("span", null, "确认信息", -1)
        ]), 2),
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "arrow" }, [
          _createElementVNode("i", { class: "el-icon el-icon-arrow-right" })
        ], -1)),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.whichStep === 2 ? 'active' : '', 'process-box'])
        }, _cache[12] || (_cache[12] = [
          _createElementVNode("div", { class: "no" }, " 3 ", -1),
          _createElementVNode("span", null, "查看结果", -1)
        ]), 2)
      ]),
      (_ctx.whichStep === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_el_upload, {
              class: "upload-demo",
              drag: !_ctx.fileName,
              disabled: !!_ctx.fileName,
              action: _ctx.uploadAction,
              headers: { Authorization: 'Bearer' + ' ' + _unref(global).token },
              accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "on-success": _ctx.fileSuccess,
              "on-error": _ctx.fileEerror,
              "show-file-list": false,
              data: { checkWay: 'sync' }
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "key" }, [
                      _createElementVNode("span", { class: "important" }, "*"),
                      _createElementVNode("span", null, "发薪数据")
                    ], -1)),
                    _createElementVNode("div", _hoisted_5, [
                      _cache[15] || (_cache[15] = _createElementVNode("button", { class: "btn import-btn" }, [
                        _createElementVNode("i", { class: "el-icon el-icon-upload2" }),
                        _createTextVNode("导入 ")
                      ], -1)),
                      _cache[16] || (_cache[16] = _createElementVNode("div", { class: "tipsText" }, " 或拖拽至此处 ", -1)),
                      _createElementVNode("a", {
                        class: "down-templete",
                        style: {"position":"relative","z-index":"10"},
                        href: "https://bullet001.oss-cn-shanghai.aliyuncs.com/file/template/template-pay.xlsx",
                        onClick: _withModifiers(
                                    () => {
                                        return;
                                    }
                                , ["stop"])
                      }, "模板下载"),
                      _createElementVNode("div", {
                        class: _normalizeClass([_ctx.fileName ? 'fileInfo' : ''])
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.fileName ? "" + "文件名" : "") + " ", 1),
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.fileName), 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.fileSize ? "" + "文件大小" : "") + " ", 1),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.fileSize), 1),
                        _createElementVNode("span", {
                          class: _normalizeClass([_ctx.fileName ? 'fileDel' : '']),
                          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDeleteFile && _ctx.onDeleteFile(...args)), ["stop"]))
                        }, _toDisplayString(_ctx.fileName ? "×" : ""), 3)
                      ], 2)
                    ])
                  ]),
                  _cache[18] || (_cache[18] = _createElementVNode("div", null, null, -1))
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "key" }, [
                      _createElementVNode("span", { class: "important" }, "*"),
                      _createElementVNode("span", null, "流程名称")
                    ], -1)),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_el_input, {
                        onClick: _withModifiers(
                                    () => {
                                        return;
                                    }
                                , ["stop"]),
                        placeholder: "请输入流程名称",
                        name: "processaName",
                        onInput: _ctx.onLoseBlur,
                        modelValue: _ctx.processaName,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.processaName) = $event))
                      }, null, 8, ["onInput", "modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "key" }, [
                      _createElementVNode("span", { class: "important" }, "*"),
                      _createElementVNode("span", null, "派遣单位名称")
                    ], -1)),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_el_select, {
                        modelValue: _ctx.enterpriseIndex,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.enterpriseIndex) = $event)),
                        filterable: "",
                        placeholder: "请选择派遣单位",
                        onChange: _ctx.onEnterprise
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.enterpriseList, (item, index) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              disabled: 
                                        item.isBlacklist || item.isForbidden
                                    ,
                              label: item.serveCompanyName,
                              value: index,
                              key: index
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.serveCompanyName), 1)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "onChange"])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _cache[21] || (_cache[21] = _createElementVNode("div", { class: "key" }, [
                      _createElementVNode("span", { class: "important" }, "*"),
                      _createElementVNode("span", null, "联系驻场")
                    ], -1)),
                    _createElementVNode("div", _hoisted_15, [
                      _createVNode(_component_el_select, {
                        onClick: _withModifiers(
                                    () => {
                                        return;
                                    }
                                , ["stop"]),
                        modelValue: _ctx.linkMan,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.linkMan) = $event)),
                        filterable: "",
                        placeholder: "请选择驻场",
                        onChange: _ctx.onLoseBlur,
                        onRemoveTag: _ctx.onRemoveTag,
                        multiple: ""
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.esidentList, (item, index) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              label: item.userName,
                              value: item.userId,
                              key: index,
                              onClick: ($event: any) => (_ctx.residentDropdown(item.userName))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.userName + " " + item.phone), 1)
                              ]),
                              _: 2
                            }, 1032, ["label", "value", "onClick"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "onChange", "onRemoveTag"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _cache[22] || (_cache[22] = _createElementVNode("div", { class: "key" }, [
                      _createElementVNode("span", { class: "important" }, "*"),
                      _createElementVNode("span", null, "备注")
                    ], -1)),
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_el_input, {
                        onClick: _withModifiers(
                                    () => {
                                        return;
                                    }
                                , ["stop"]),
                        placeholder: "请输入备注信息",
                        onInput: _ctx.onLoseBlur,
                        name: _ctx.remarks,
                        modelValue: _ctx.remarks,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.remarks) = $event))
                      }, null, 8, ["onInput", "name", "modelValue"])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _cache[23] || (_cache[23] = _createElementVNode("div", { class: "key" }, [
                      _createElementVNode("span", { class: "important" }, "*"),
                      _createElementVNode("span", null, "总金额")
                    ], -1)),
                    _createElementVNode("div", _hoisted_20, [
                      _createVNode(_component_el_input, {
                        placeholder: "上传表单后自动填充",
                        disabled: "",
                        name: "totalAmount",
                        modelValue: _ctx.totalAmount,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.totalAmount) = $event))
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _cache[24] || (_cache[24] = _createElementVNode("div", { class: "key" }, [
                      _createElementVNode("span", { class: "important" }, "*"),
                      _createElementVNode("span", null, "总人数")
                    ], -1)),
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_el_input, {
                        placeholder: "上传表单后自动填充",
                        disabled: "",
                        name: "totalNumber",
                        modelValue: _ctx.totalNumber,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.totalNumber) = $event))
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ]),
                _createVNode(_component_el_button, {
                  class: "btn mini-btn",
                  type: "primary",
                  disabled: _ctx.isOnBtn,
                  onClick: _withModifiers(_ctx.onUploadFileNext, ["stop"])
                }, {
                  default: _withCtx(() => _cache[25] || (_cache[25] = [
                    _createTextVNode("下一步")
                  ])),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ]),
              _: 1
            }, 8, ["drag", "disabled", "action", "headers", "on-success", "on-error"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.errorList.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
            _createVNode(_component_el_alert, {
              title: 
                    `本次上传文件共发现${_ctx.errorList.length}处错误，请修改后重新上传`
                ,
              type: "error",
              center: "",
              "show-icon": ""
            }, null, 8, ["title"]),
            _createElementVNode("div", _hoisted_24, [
              _createVNode(_component_el_table, { data: _ctx.errorList }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    label: "行号",
                    style: {"color":"#F5F5F7"},
                    formatter: row => row.orderNum + 1
                  }, null, 8, ["formatter"]),
                  _createVNode(_component_el_table_column, {
                    prop: "userName",
                    style: {"color":"#F5F5F7"},
                    label: "姓名"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "idCard",
                    style: {"color":"#F5F5F7"},
                    label: "身份证号"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "detailRemarks",
                    style: {"color":"#F5F5F7"},
                    label: "备注"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "errorMsg",
                    style: {"color":"#F5F5F7"},
                    label: "错误信息"
                  })
                ]),
                _: 1
              }, 8, ["data"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.whichStep === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, [
              _cache[26] || (_cache[26] = _createElementVNode("div", { class: "info-title" }, " 发薪数据单 ", -1)),
              _createVNode(_unref(ElScrollbar), { "max-height": "430px" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table, {
                    data: _ctx.fileContentList,
                    "row-class-name": _ctx.tableRowClassName,
                    style: {"width":"100%"},
                    "header-row-style": { color: '#202536' },
                    "row-style": { color: '#5E617D' },
                    "cell-style": {
                            height: '35px',
                            padding: '0'
                        }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_table_column, {
                        type: "index",
                        label: "序号",
                        width: "50"
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "userName",
                        width: "80",
                        label: "姓名"
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "idCard",
                        label: "身份证号",
                        width: "170"
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "totalMoney",
                        label: "薪资"
                      }, {
                        default: _withCtx((scope) => [
                          (_ctx.editId !== scope.row.id)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_27, _toDisplayString(scope.row.totalMoney), 1))
                            : (_openBlock(), _createElementBlock("p", _hoisted_28, [
                                _createVNode(_component_el_input, {
                                  placeholder: "薪资",
                                  modelValue: scope.row.totalMoney,
                                  "onUpdate:modelValue": ($event: any) => ((scope.row.totalMoney) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "totalMoney",
                        label: "备注"
                      }, {
                        default: _withCtx((scope) => [
                          (_ctx.editId !== scope.row.id)
                            ? (_openBlock(), _createElementBlock("p", _hoisted_29, _toDisplayString(scope.row.detailRemarks), 1))
                            : (_openBlock(), _createElementBlock("p", _hoisted_30, [
                                _createVNode(_component_el_input, {
                                  maxlength: "20",
                                  placeholder: "备注",
                                  modelValue: scope.row.detailRemarks,
                                  "onUpdate:modelValue": ($event: any) => ((scope.row.detailRemarks) = $event)
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_table_column, {
                        label: "操作",
                        width: "50"
                      }, {
                        default: _withCtx((scope) => [
                          _createElementVNode("p", {
                            class: "link",
                            onClick: ($event: any) => (_ctx.onFileEdit(scope.row.id))
                          }, _toDisplayString(_ctx.editId === scope.row.id
                                            ? "保存"
                                            : "编辑"), 9, _hoisted_31)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["data", "row-class-name"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_pagination, {
                class: "pagination",
                layout: "prev, pager, next",
                onCurrentChange: _ctx.handleCurrentChange,
                "page-size": 100,
                total: _ctx.userList.length
              }, null, 8, ["onCurrentChange", "total"])
            ]),
            _createElementVNode("div", _hoisted_32, [
              _cache[34] || (_cache[34] = _createElementVNode("div", { class: "info-title" }, " 确认信息 ", -1)),
              _createElementVNode("div", _hoisted_33, [
                _cache[27] || (_cache[27] = _createElementVNode("div", { class: "info-left" }, " 附件名称： ", -1)),
                _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.fileName), 1)
              ]),
              _createElementVNode("div", _hoisted_35, [
                _cache[28] || (_cache[28] = _createElementVNode("div", { class: "info-left" }, " 流程名称： ", -1)),
                _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.processaName), 1)
              ]),
              _createElementVNode("div", _hoisted_37, [
                _cache[29] || (_cache[29] = _createElementVNode("div", { class: "info-left" }, " 备注： ", -1)),
                _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.remarks), 1)
              ]),
              _createElementVNode("div", _hoisted_39, [
                _cache[30] || (_cache[30] = _createElementVNode("div", { class: "info-left" }, " 派遣单位： ", -1)),
                _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.enterpriseName), 1)
              ]),
              _createElementVNode("div", _hoisted_41, [
                _cache[31] || (_cache[31] = _createElementVNode("div", { class: "info-left" }, " 联系驻厂： ", -1)),
                _createElementVNode("div", _hoisted_42, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactResident, (item, index) => {
                    return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(item + " "), 1))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_43, [
                _cache[32] || (_cache[32] = _createElementVNode("div", { class: "info-left" }, " 总人数： ", -1)),
                _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.totalNumber) + "人", 1)
              ]),
              _createElementVNode("div", _hoisted_45, [
                _cache[33] || (_cache[33] = _createElementVNode("div", { class: "info-left" }, " 总金额： ", -1)),
                _createElementVNode("div", _hoisted_46, " ￥" + _toDisplayString(_ctx.totalAmount), 1)
              ]),
              _createElementVNode("div", _hoisted_47, [
                _createElementVNode("button", {
                  class: "btn-white",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.whichStep -= 1))
                }, " 重新选择 "),
                _createElementVNode("button", {
                  class: "btn",
                  onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onAddBatchPay && _ctx.onAddBatchPay(...args)))
                }, "确认添加")
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.whichStep === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
            _cache[42] || (_cache[42] = _createElementVNode("img", {
              src: _imports_0,
              alt: "",
              class: "result-success"
            }, null, -1)),
            _cache[43] || (_cache[43] = _createElementVNode("div", { class: "result-title" }, " 提交审核成功 ", -1)),
            _createElementVNode("button", {
              class: "btn",
              onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onCloseEject && _ctx.onCloseEject(...args)))
            }, "返回列表"),
            _createElementVNode("div", _hoisted_49, [
              _createElementVNode("div", _hoisted_50, [
                _cache[35] || (_cache[35] = _createElementVNode("div", { class: "info-left" }, " 附件名称： ", -1)),
                _createElementVNode("div", _hoisted_51, _toDisplayString(_ctx.fileName), 1)
              ]),
              _createElementVNode("div", _hoisted_52, [
                _cache[36] || (_cache[36] = _createElementVNode("div", { class: "info-left" }, " 流程名称： ", -1)),
                _createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.processaName), 1)
              ]),
              _createElementVNode("div", _hoisted_54, [
                _cache[37] || (_cache[37] = _createElementVNode("div", { class: "info-left" }, " 备注： ", -1)),
                _createElementVNode("div", _hoisted_55, _toDisplayString(_ctx.remarks), 1)
              ]),
              _createElementVNode("div", _hoisted_56, [
                _cache[38] || (_cache[38] = _createElementVNode("div", { class: "info-left" }, " 发薪派遣单位： ", -1)),
                _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.enterpriseName), 1)
              ]),
              _createElementVNode("div", _hoisted_58, [
                _cache[39] || (_cache[39] = _createElementVNode("div", { class: "info-left" }, " 联系驻厂： ", -1)),
                _createElementVNode("div", _hoisted_59, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactResident, (item, index) => {
                    return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(item + " "), 1))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_60, [
                _cache[40] || (_cache[40] = _createElementVNode("div", { class: "info-left" }, " 总人数： ", -1)),
                _createElementVNode("div", _hoisted_61, _toDisplayString(_ctx.totalNumber) + "人", 1)
              ]),
              _createElementVNode("div", _hoisted_62, [
                _cache[41] || (_cache[41] = _createElementVNode("div", { class: "info-left" }, " 总金额： ", -1)),
                _createElementVNode("div", _hoisted_63, " ￥" + _toDisplayString(_ctx.totalAmount), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})