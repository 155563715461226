import request from './request'

export interface AlipayRechargeResponseDto {
    code: null;
    msg: null;
    subCode: null;
    subMsg: null;
    body: string;
    params: null;
    orderId: null;
    outBizNo: null;
    status: null;
    success: boolean;
    errorCode: null;
}

/**
 * 支付宝充值
 * @param data
 * totalAmount: 金额
 * @returns
 */
export const alipayRecharge = async (data: {
    totalAmount: string | number;
}): Promise<AlipayRechargeResponseDto> => {
    return await request.post('/alipay/account/recharge', data)
}

export interface TyxSign {
    isOpen: number;
    balance: number;
}

export interface SendMass {
    isOpen: boolean;
    massAmount: number;
}

export interface Pay {
    unclaimedSalary: number;
    isOpen: boolean;
    balance: number;
    hasClaimingSalary: boolean;
    advanceUnclaimedSalary: number;
    canUseBalance: number;
    claimingSalary: number;
}

export interface Wesure {
    isOpen: boolean;
    balance: number;
}

export interface Alipay {
    unclaimedSalary: number;
    balance: number;
    hasClaimingSalary: boolean;
    isOpenAlipay: boolean;
    advanceUnclaimedSalary: number;
    canUseBalance: number;
    claimingSalary: number;
}

export interface Account {
    accountExpireTime: string | null;
}
export interface AccountProductStatusDto {
    alipay: Alipay;
    wesure: Wesure;
    periodInsur: Wesure;
    pay: Pay;
    sendMass: SendMass;
    eleSign: Wesure;
    tyxSign: TyxSign;
    account: Account;
}

/**
 * 获取账户产品状态
 * @returns
 */
export const getAccountProductStatusApi = async (): Promise<AccountProductStatusDto> => {
    return await request.get('/rest/home/statistics/account-product-status')
}

/**
 * 切换公司
 * @returns
 */
interface LoginResDto {
    token: string
}
export const replaceCompany = async (data: {
    companyId: string | number;
    username: string | number;
}): Promise<LoginResDto> => {
    return await request.post('/switch-company', data)
}

export interface CompanyItemDto {
    companyId: number;
    createTime: string;
    companyName: string;
    isSelected: number;
    superAdminName: string;
    auditStatus: number;
    id: number;
    position: string;
    isAdmin: number;
    userId: number;
}
/**
 * 获取公司列表
 * @returns
 */
export const getCompanyListApi = async (data: {
    userId?: string | number;
    phone?: string;
}): Promise<CompanyItemDto[]> => {
    return await request.get('/rest/company/company-list', { params: data })
}

export interface CompanyInfoDto {
  id: number;
  source: number;
  companyName: string;
  companyAbbreviation: string;
  creditCode: string;
  merchantBankCard: string;
  auditStatus: number;
  legalPerson: string;
  area: string;
  subMchid: string;
  dailyQuota: number;
  personSingleQuota: number;
  createTime: string;
  openAnAccountTime: string;
  isOpenSalaryCard: number;
  isOpenPaySalary: number;
  isOpenWesure: number;
  isOpenPayRepeatReminder: number;
  isNeedAuthSalaryCard: number;
  isOpenZp: number;
  balanceRemind: number;
  insureCompanyType: string;
  qyWxQrcodeId: number;
  bookSubUnit: string;
  qyWxQrcodeUrl: null;
  isWesureUser: number;
  createUserId: number;
  updateTime: string;
  updateUserId: number;
  ids: null;
  expirationDate: null;
  wesureBalance: null;
  insureTypeList: null;
  isOpenPeriod: null;
  periodCompanyTypeList: null;
  periodPlanTypeList: null;
  periodPlanTypeNameList: null;
  insureType: string;
  policyPeopleCount: null;
  policyCount: null;
  wesureType: null;
  wesureTypeName: string;
  isOpenEleSign: number;
  signInMode: number;
  salesName: null;
  params?: any;
  payAccountRechargeRecords: null;
  rechargeMonthNumber: number;
  accountExpireTime: string;
  accountOpeningFee: null;
  validTime: null;
  employeeLibrary: null;
  receiptId: null;
  bankName: null;
  isOpenTyrbStatus: null;
  insurCompany: null;
  channelId: null;
  channelName: null;
  businessLicenseAddress: string;
  queryDate: null;
  productType: null;
  bySeatCount: null;
  status: null;
  companyNameList: null;
  notLikeCompanyName: null;
  accountLimit: null;
  operatorName: null;
  operatorIdCard: null;
  contact: null;
  isAgent: null;
  isOpenAlipay: null;
  isOpenWechatPay: null;
  openQrProjectEnsure: null;
  wxAccountBalance: null;
  alipayAccountBalance: null;
  openWesure: boolean;
  openEleSign: boolean;
}
/**
 * 获取公司详情
 * @returns
 */
export const getCompanyInfoApi = async ():Promise<CompanyInfoDto> => {
    return await request.get('/rest/company/get-company-info')
}

/**
 * 支付宝充值回调
 * @returns
 */
export const queryRechargeAndSync = async () => {
    return await request.get('/rest/pay/alipay/sub/merchant/recharge/record/query-recharge-and-sync')
}
