import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "container",
  class: "container-c"
}
const _hoisted_2 = ["id", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        id: 'tab' + index,
        class: _normalizeClass(['tab', _ctx.active == index ? 'active' : '', 'point']),
        onClick: ($event: any) => (_ctx.onTab(index)),
        key: index
      }, _toDisplayString(item), 11, _hoisted_2))
    }), 128))
  ]))
}