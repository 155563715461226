import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../assets/home/icon-1.png'
import _imports_1 from '../../../assets/home/icon-2.png'


const _hoisted_1 = { class: "flex flex-jc-sb gap-20" }
const _hoisted_2 = { class: "flex-item flex-1 border mg-l-16" }
const _hoisted_3 = { class: "flex flex-ai-c" }
const _hoisted_4 = { class: "mg-l-10" }
const _hoisted_5 = { class: "fs-18 bold" }
const _hoisted_6 = { class: "mg-l-10 bg" }
const _hoisted_7 = { class: "flex mg-t-20" }
const _hoisted_8 = { class: "fs-14 mg-t-5 text-i bold" }
const _hoisted_9 = { class: "mg-l-30" }
const _hoisted_10 = { class: "fs-14 mg-t-5 text-i bold" }
const _hoisted_11 = { class: "mg-l-30" }
const _hoisted_12 = { class: "fs-14 mg-t-5 text-i bold" }
const _hoisted_13 = { class: "flex-item flex-1 mg-l-30" }
const _hoisted_14 = { class: "flex flex-ai-c" }
const _hoisted_15 = { class: "mg-l-10" }
const _hoisted_16 = { class: "fs-18 bold" }
const _hoisted_17 = { class: "mg-l-10 bg" }
const _hoisted_18 = { class: "flex mg-t-20" }
const _hoisted_19 = { class: "fs-14 mg-t-5 text-i bold" }
const _hoisted_20 = { class: "mg-l-30" }
const _hoisted_21 = { class: "fs-14 mg-t-5 text-i bold" }
const _hoisted_22 = { class: "mg-l-30" }
const _hoisted_23 = { class: "fs-14 mg-t-5 text-i bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("img", {
          class: "icon",
          src: _imports_0,
          alt: ""
        }, null, -1)),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fs-12 fc-lightgray mg-l-10" }, "实发金额", -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "fs-12" }, "¥", -1)),
          _createElementVNode("i", _hoisted_5, _toDisplayString(_ctx.detail.payTotalMoney), 1)
        ]),
        _createElementVNode("div", _hoisted_6, "共" + _toDisplayString(_ctx.detail.payOrderCount) + "个项目", 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", null, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "fs-12 fc-lightgray" }, "已领金额", -1)),
          _createElementVNode("div", _hoisted_8, [
            _cache[3] || (_cache[3] = _createElementVNode("span", null, "¥", -1)),
            _createTextVNode(_toDisplayString(_ctx.detail.payReceiveTotalMoney), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "fs-12 fc-lightgray" }, "待领金额", -1)),
          _createElementVNode("div", _hoisted_10, [
            _cache[5] || (_cache[5] = _createElementVNode("span", null, "¥", -1)),
            _createTextVNode(_toDisplayString(_ctx.detail.payAvailableTotalMoney), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "fs-12 fc-lightgray" }, "停发金额", -1)),
          _createElementVNode("div", _hoisted_12, [
            _cache[7] || (_cache[7] = _createElementVNode("span", null, "¥", -1)),
            _createTextVNode(_toDisplayString(_ctx.detail.payStopTotalMoney), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _cache[10] || (_cache[10] = _createElementVNode("img", {
          class: "icon",
          src: _imports_1,
          alt: ""
        }, null, -1)),
        _cache[11] || (_cache[11] = _createElementVNode("span", { class: "fs-12 fc-lightgray mg-l-10" }, "实发预支", -1)),
        _createElementVNode("div", _hoisted_15, [
          _cache[9] || (_cache[9] = _createElementVNode("span", { class: "fs-12" }, "¥", -1)),
          _createElementVNode("i", _hoisted_16, _toDisplayString(_ctx.detail.totalPracticalAdvanceAmount), 1)
        ]),
        _createElementVNode("div", _hoisted_17, "共" + _toDisplayString(_ctx.detail.advanceOrderCount) + "笔", 1)
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", null, [
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "fs-12 fc-lightgray" }, "已领预支", -1)),
          _createElementVNode("div", _hoisted_19, [
            _cache[12] || (_cache[12] = _createElementVNode("span", null, "¥", -1)),
            _createTextVNode(_toDisplayString(_ctx.detail.totalSalaryAdvanceAmount), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "fs-12 fc-lightgray" }, "待领预支", -1)),
          _createElementVNode("div", _hoisted_21, [
            _cache[14] || (_cache[14] = _createElementVNode("span", null, "¥", -1)),
            _createTextVNode(_toDisplayString(_ctx.detail.totalUnclaimedAdvanceAmount), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "fs-12 fc-lightgray" }, "停发预支", -1)),
          _createElementVNode("div", _hoisted_23, [
            _cache[16] || (_cache[16] = _createElementVNode("span", null, "¥", -1)),
            _createTextVNode(_toDisplayString(_ctx.detail.totalStopAdvanceAmount), 1)
          ])
        ])
      ])
    ])
  ]))
}