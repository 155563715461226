import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import global from '@/utils/global'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        meta: {
            titles: ['首页']
        },
        component: Home
    },
    {
        path: '/account-details',
        name: 'AccountDetails',
        meta: {
            titles: ['发薪专户详情']
        },
        component: () =>
            import('../views/account-details.vue')
    },
    {
        path: '/login/:code?',
        name: 'Login',
        meta: {
            isNav: false
        },
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/login.vue')
    },
    {
        path: '/book',
        name: 'Book',
        meta: {
            titles: ['简历库']
        },
        component: () =>
            import(/* webpackChunkName: "book" */ '../views/book.vue')
    },
    {
        path: '/book/detail/:id',
        name: 'BookDetail',
        meta: {
            titles: ['简历库', '工作记录'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "bookDetail" */ '../views/book-detail.vue'
            )
    },
    {
        path: '/enterprise',
        name: 'Enterprise',
        meta: {
            titles: ['派遣单位管理']
        },
        component: () =>
            import(
                /* webpackChunkName: "enterprise" */ '../views/enterprise.vue'
            )
    },
    {
        path: '/enterpriseList',
        name: 'EnterpriseList',
        meta: {
            titles: ['企业管理']
        },
        component: () =>
            import(
                /* webpackChunkName: "enterprise" */ '../views/enterprise-list.vue'
            )
    },
    {
        path: '/enterprise/detail/:id',
        name: 'EnterpriseDetail',
        meta: {
            titles: ['派遣单位管理', '派遣单位详情'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "enterprise-detail" */ '../views/enterprise-detail.vue'
            )
    },
    {
        path: '/project/make/:id',
        name: 'ProjectMake',
        meta: {
            titles: ['派遣单位管理', '派遣单位详情', '名单详情'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "project-make" */ '../views/project-make.vue'
            )
    },
    {
        path: '/pay',
        name: 'Pay',
        meta: {
            titles: ['发薪记录']
        },
        // component: () => import(/* webpackChunkName: "pay" */ '../views/pay.vue')
        component: () =>
            import(
                /* webpackChunkName: "pay" */ '../views/salary/management-salary/pay-salary.vue'
            )
    },
    {
        path: '/pay/detail',
        name: 'PayDetail',
        meta: {
            titles: ['发薪', '发薪明细'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "pay-detail" */ '../views/salary/management-salary/detail-pay-record.vue'
            )
    },
    {
        path: '/payroll',
        name: 'Payroll',
        meta: {
            titles: ['工资单']
        },
        component: () =>
            import(/* webpackChunkName: "payroll" */ '../views/payroll.vue')
    },
    {
        path: '/projectManagement',
        name: 'ProjectManagement',
        meta: {
            titles: ['项目管理']
        },
        component: () =>
            import(
                /* webpackChunkName: "project" */ '../views/salary/management-project/index.vue'
            )
    },
    {
        path: '/payroll/detail/:id',
        name: 'PayrollDetail',
        meta: {
            titles: ['工资单', '查看详情'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "payroll-detail" */ '../views/payroll-detail.vue'
            )
    },
    {
        path: '/project/place',
        name: 'ProjectPlace',
        meta: {
            titles: ['归档管理']
        },
        component: () =>
            import(
                /* webpackChunkName: "project-place" */ '../views/project-place.vue'
            )
    },
    {
        path: '/project/place/detail/:id',
        name: 'ProjectPlaceDetail',
        meta: {
            titles: ['归档管理', '归档详情'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "project-place-detail" */ '../views/project-place-detail.vue'
            )
    },
    {
        path: '/contract/record',
        name: 'ContractRecord',
        meta: {
            titles: ['合同记录']
        },
        component: () =>
            import(
                /* webpackChunkName: "contract-record" */ '../views/contract-list.vue'
            )
    },
    {
        path: '/sign',
        name: 'Sign',
        meta: {
            titles: ['考勤统计']
        },
        component: () =>
            import(/* webpackChunkName: "sign" */ '../views/sign.vue')
    },
    {
        path: '/sign/detail/:start/:end/:title/:name/:id/:type',
        name: 'SignDetail',
        meta: {
            titles: ['考勤统计', '考勤汇总'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "sign-detail" */ '../views/sign-detail.vue'
            )
    },
    {
        path: '/contract/template',
        name: 'ContractTemplate',
        meta: {
            titles: ['合同模板']
        },
        component: () =>
            import(
                /* webpackChunkName: "contract-templete" */ '../views/contract-template.vue'
            )
    },
    {
        path: '/bill',
        name: 'Bill',
        meta: {
            titles: ['对账中心']
        },
        component: () =>
            import(
                /* webpackChunkName: "bill" */ '../views/salary/management-salary/bill-center.vue'
            )
    },
    {
        path: '/bill/detail',
        name: 'BillDetail',
        meta: {
            titles: ['对账中心', '查看详情'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "bill-detail" */ '../views/salary/management-salary/bill-center-detail.vue'
            )
    },
    {
        path: '/bill/advanceDetail',
        name: 'AdvanceDetail',
        meta: {
            titles: ['对账中心', '对账详情'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "bill-detail" */ '../views/account-check/advance-list-detail.vue'
            )
    },
    {
        path: '/bill/accountCheck',
        name: 'AccountCheck',
        meta: {
            titles: ['对账中心', '核算'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "account-check" */ '../views/account-check/account-check.vue'
            )
    },
    {
        path: '/bill/accountCheckDetail',
        name: 'AccountCheckDetail',
        meta: {
            titles: ['对账中心', '核算详情'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "account-check-detail" */ '../views/account-check/account-check-detail.vue'
            )
    },
    {
        path: '/long-insure-worker/:type?',
        name: 'long-insure-worker',
        meta: {
            titles: ['员工查询']
        },
        component: () =>
            import(
                /* webpackChunkName: "bill" */ '../views/long-insure-worker.vue'
            )
    },
    {
        path: '/payrollrecord',
        name: 'PayrollRecord',
        meta: {
            titles: ['员工账单']
        },
        component: () =>
            import(
                /* webpackChunkName: "payroll-record" */ '../views/payroll-record.vue'
            )
    },
    {
        path: '/staffBillDetail',
        name: 'StaffBillDetail',
        meta: {
            titles: ['员工账单', '员工账单详情']
        },
        component: () =>
            import(
                /* webpackChunkName: "payroll-record" */ '../views/salary/management-salary/detail-staff-bill.vue'
            )
    },
    {
        path: '/payrollrecordown',
        name: 'PayrollRecordOwn',
        meta: {
            titles: ['发薪账单']
        },
        component: () =>
            import(
                /* webpackChunkName: "payroll-record" */ '../views/salary/management-salary/bill-list-staff.vue'
            )
        //   component: () => import(/* webpackChunkName: "payroll-recordOwn" */ '../views/payroll-record-own.vue')
    },
    {
        path: '/long-insure',
        name: 'LongInsure',
        meta: {
            titles: ['长期工保险']
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/long-insure.vue'
            )
    },
    {
        path: '/long-insure-bills',
        name: 'LongInsureBills',
        meta: {
            titles: ['长期工保险-账单明细']
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/long-insure-bills.vue'
            )
    },
    {
        path: '/long-insure/ensure',
        name: 'LongEnsureWorker',
        meta: {
            titles: ['长期保险', '在保人数']
        },
        component: () =>
            import(
                /* webpackChunkName: "bill" */ '../views/long-ensure-worker.vue'
            )
    },
    {
        path: '/longinsure/bill',
        name: 'LongInsureBill',
        meta: {
            // titles: ['日保', '日日保', '账单明细']
            titles: ['长期保', '账单明细']
        },
        component: () =>
            import(
                /* webpackChunkName: "insure-bill" */ '../views/long-insure-bill.vue'
            )
    },
    {
        path: '/long-insure/detail/:id',
        name: 'LongInsureDetail',
        meta: {
            titles: ['长期工保险', '详情页'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/long-insure-detail.vue'
            )
    },
    {
        path: '/long-insure-add-subtract/:type/:id/:echo?',
        name: 'LongInsureAddSubtract',
        meta: {
            titles: ['长期工保险', '详情页', '加保'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/long-insure-add-subtract.vue'
            )
    },
    // long-insure-detail
    {
        path: '/long-insure-dispatch',
        name: 'LongInsureDispatch',
        meta: {
            titles: ['长期保', '被派遣单位']
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/long-insure-dispatch.vue'
            )
    },
    {
        path: '/long-insure-report',
        name: 'LongInsureReport',
        meta: {
            titles: ['报案理赔']
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/long-insure-report.vue'
            )
    },
    {
        path: '/long-insure-report-detail/:id',
        name: 'LongInsureReportDetail',
        meta: {
            titles: ['报案理赔', '理赔详情'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/long-insure-report-detail.vue'
            )
    },
    {
        path: '/long-insure-report-add',
        name: 'LongInsureReportAdd',
        meta: {
            titles: ['报案理赔']
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/long-insure-report-add.vue'
            )
    },
    // long-insure-dispatch long-insure-report long-insure-report-add
    {
        path: '/long-insure-worker',
        name: 'LongInsureWorker',
        meta: {
            titles: ['员工查询']
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/long-insure-worker.vue'
            )
    },
    {
        path: '/code/change',
        name: 'CodeChange',
        meta: {
            titles: ['修改密码'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "code-change" */ '../views/code-change.vue'
            )
    },
    {
        path: '/insure/deduction',
        name: 'InsureDeduction',
        meta: {
            titles: ['日保', '日日保']
            // titles: ['日日保', '投保扣费记录']
        },
        component: () =>
            import(
                /* webpackChunkName: "insure-deduction" */ '../views/insure-deduction.vue'
            )
    },
    {
        path: '/insure/bill',
        name: 'InsureBill',
        meta: {
            titles: ['日保', '日日保', '账单明细']
            // titles: ['日日保', '账单明细']
        },
        component: () =>
            import(
                /* webpackChunkName: "insure-bill" */ '../views/insure-bill.vue'
            )
    },
    {
        path: '/insure-worker/:type?',
        name: 'InsureWorker',
        meta: {
            titles: ['日保', '日日保', '员工查询']
            // titles: ['日日保', '员工查询']
        },
        component: () =>
            import(/* webpackChunkName: "bill" */ '../views/insure-worker.vue')
    },
    {
        path: '/insure-dispatch',
        name: 'InsureDispatch',
        meta: {
            titles: ['日保', '日日保', '被派遣单位']
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/insure-dispatch.vue'
            )
    },
    {
        path: '/insure/team',
        name: 'TeamInsure',
        meta: {
            titles: ['日保', '团意日保']
        },
        component: () =>
            import(
                /* webpackChunkName: "insure-deduction" */ '../views/insure-team.vue'
            )
    },
    {
        path: '/insure/team/bill',
        name: 'insureTeamBill',
        meta: {
            titles: ['日保', '团意日保', '账单明细']
        },
        component: () =>
            import(
                /* webpackChunkName: "insure-deduction" */ '../views/insure-team-bill.vue'
            )
    },
    {
        path: '/insure/team/worker',
        name: 'InsureTeamWorker',
        meta: {
            titles: ['日保', '团意日保', '员工查询']
        },
        component: () =>
            import(
                /* webpackChunkName: "bill" */ '../views/insure-team-worker.vue'
            )
    },
    {
        path: '/insure-team-report',
        name: 'InsureTeamReport',
        meta: {
            titles: ['日保', '团意日保', '报案理赔']
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/insure-team-report.vue'
            )
    },
    {
        path: '/insure-team-report-detail/:id',
        name: 'InsureTeamReportDetail',
        meta: {
            titles: ['日保', '团意日保', '报案理赔', '理赔详情'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/insure-team-report-detail.vue'
            )
    },
    {
        path: '/insure-team-report-add',
        name: 'InsureTeamReportAdd',
        meta: {
            titles: ['日保', '团意日保', '报案理赔', '新增报案']
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/insure-team-report-add.vue'
            )
    },
    //
    {
        path: '/insure-report',
        name: 'InsureReport',
        meta: {
            titles: ['日保', '日日保', '报案理赔']
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/insure-report.vue'
            )
    },
    {
        path: '/insure-report-detail/:id',
        name: 'InsureReportDetail',
        meta: {
            titles: ['日保', '日日保', '报案理赔', '理赔详情'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/insure-report-detail.vue'
            )
    },
    {
        path: '/insure-report-add',
        name: 'InsureReportAdd',
        meta: {
            titles: ['日保', '日日保', '报案理赔', '新增报案']
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/insure-report-add.vue'
            )
    },
    {
        path: '/insure/tema/company',
        name: 'InsureTeamCompany',
        meta: {
            titles: ['被派遣单位']
        },
        component: () =>
            import(
                /* webpackChunkName: "insure-deduction" */ '../views/insure-team-company.vue'
            )
    },
    {
        path: '/insure/deduction/detail/:id',
        name: 'InsureDeductionDetail',
        meta: {
            titles: ['投保扣费记录', '详情'],
            keepAlive: false
        },
        component: () =>
            import(
                /* webpackChunkName: "insure-deduction-detail" */ '../views/insure-deduction-detail.vue'
            )
    },
    {
        path: '/insure/company',
        name: 'InsureCompany',
        meta: {
            titles: ['被派遣单位']
        },
        component: () =>
            import(
                /* webpackChunkName: "insure-deduction" */ '../views/insure-company.vue'
            )
    },
    {
        path: '/recharge/record',
        name: 'RechargeRecord',
        meta: {
            titles: ['充值记录']
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/recharge-record.vue'
            )
    },
    {
        path: '/download',
        name: 'Download',
        meta: {
            titles: ['下载中心']
        },
        component: () =>
            import(
                /* webpackChunkName: "recharge-record" */ '../views/download-zone.vue'
            )
    },
    {
        path: '/advance/applay',
        name: 'AdvanceApplay',
        meta: {
            titles: ['预支', '预支申请']
            // titles: ['日日保', '投保扣费记录']
        },
        component: () =>
            import(
                /* webpackChunkName: "advance-applay" */ '../views/advance/advance-applay.vue'
            )
    },
    {
        path: '/advance/applayDetail',
        name: 'AdvanceApplayDetail',
        meta: {
            titles: ['预支', '申请单详情'],
            keepAlive: false
            // titles: ['日日保', '投保扣费记录']
        },
        component: () =>
            import(
                /* webpackChunkName: "applay-detail" */ '../views/advance/advance-applay-detail.vue'
            )
    },
    {
        path: '/advance/setting',
        name: 'AdvanceSetting',
        meta: {
            titles: ['预支', '预支设置']
            // titles: ['日日保', '投保扣费记录']
        },
        component: () =>
            import(
                /* webpackChunkName: "applay-setting" */ '../views/advance/advance-setting.vue'
            )
    },
    {
        path: '/agreement/serviceAgreement',
        name: 'ServiceAgreement',
        meta: {
            isNav: false,
            titles: ['协议', '服务协议']
        },
        component: () =>
            import(
                /* webpackChunkName: "service-agreement" */ '../views/agreement/service-agreement.vue'
            )
    },
    {
        path: '/agreement/privateAgreement',
        name: 'PrivateAgreement',
        meta: {
            isNav: false,
            titles: ['协议', '隐私协议']
        },
        component: () =>
            import(
                /* webpackChunkName: "service-agreement" */ '../views/agreement/private-agreement.vue'
            )
    },
    {
        path: '/bigScreen',
        name: 'BigScreen',
        meta: {
            titles: ['大屏']
        },
        component: () =>
            import(
                /* webpackChunkName: "big-screen" */ '../views/big-screen/index.vue'
            )
    }
    //
]
// insure-deduction-detail
const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    // 控制菜单栏是否显示
    store.commit('setNavStatus', to.meta.isNav !== false)
    store.commit('setTitles', to.meta.titles)
    // 没有token时自动跳转到登录界面
    console.log(to)
    console.log(to.name, to.path, 'path123')
    if (to.path === '/login' && sessionStorage.token) {
        next('/')
        store.commit('setNavStatus', true)
        console.log('进入首页')
    }
    if (!global.token && !sessionStorage.token && to.name !== 'Login') {
        next('/login')
        // 符合条件则左侧导航栏隐藏
        store.commit('setNavStatus', false)
        console.log('进入login')
    } else {
        next()
    }
})
export default router
