import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/recharge/hot.png'
import _imports_1 from '../../assets/recharge/weixin-recharge-icon.png'
import _imports_2 from '../../assets/recharge/icon-seat.png'
import _imports_3 from '../../assets/recharge/success.png'


const _hoisted_1 = {
  key: 0,
  class: "dialog-container"
}
const _hoisted_2 = { class: "dialog-box" }
const _hoisted_3 = { class: "dialog-header flex flex-jc-sb flex-ai-c" }
const _hoisted_4 = { class: "close fc-white fs-24" }
const _hoisted_5 = { class: "dialog-body" }
const _hoisted_6 = {
  key: 0,
  class: "flex gap-16"
}
const _hoisted_7 = { class: "left fs-12" }
const _hoisted_8 = { class: "left-item flex flex-ai-c" }
const _hoisted_9 = { class: "left-item flex flex-ai-c" }
const _hoisted_10 = { class: "left-item flex flex-ai-c" }
const _hoisted_11 = { class: "center w-full" }
const _hoisted_12 = {
  key: 0,
  class: "tip-box fs-12 mg-t-16 flex flex-ai-c"
}
const _hoisted_13 = { class: "fc-orange" }
const _hoisted_14 = {
  class: "flex w-full gap-16 mg-t-30",
  style: {"justify-content":"space-between"}
}
const _hoisted_15 = { class: "linear-inner-box no-select" }
const _hoisted_16 = { class: "fs-14 bold" }
const _hoisted_17 = { class: "line-through fs-12 normal mg-l-5 fc-lightblack" }
const _hoisted_18 = { class: "hot-hint" }
const _hoisted_19 = { class: "triangle-box" }
const _hoisted_20 = { class: "shadow-box box2" }
const _hoisted_21 = { class: "linear-box pointer" }
const _hoisted_22 = { class: "linear-inner-box" }
const _hoisted_23 = { class: "flex flex-jc-sb flex-ai-c" }
const _hoisted_24 = { class: "fc-orange fs-20 ff-dd bold" }
const _hoisted_25 = { class: "screw-container" }
const _hoisted_26 = { class: "screw-box fs-12" }
const _hoisted_27 = { class: "screw-content flex flex-ai-c" }
const _hoisted_28 = { class: "set-pd" }
const _hoisted_29 = { class: "border-box info-detail" }
const _hoisted_30 = { class: "tab-1" }
const _hoisted_31 = { class: "flex mg-t-16 gap-16" }
const _hoisted_32 = { class: "flex1" }
const _hoisted_33 = {
  key: 0,
  class: "flex-1 fs-12"
}
const _hoisted_34 = { class: "mg-b-12 flex" }
const _hoisted_35 = { class: "bold fc-orange" }
const _hoisted_36 = {
  key: 1,
  class: "flex-1 fs-12"
}
const _hoisted_37 = { class: "mg-b-12 flex" }
const _hoisted_38 = { class: "bold fc-orange" }
const _hoisted_39 = { class: "tab-2" }
const _hoisted_40 = { class: "flex-1 detail-box info-detail" }
const _hoisted_41 = { class: "flex gap-48" }
const _hoisted_42 = { class: "mg-b-10 fs-12 flex-base-300" }
const _hoisted_43 = { class: "item-value" }
const _hoisted_44 = { class: "mg-b-10 fs-12" }
const _hoisted_45 = { class: "item-value" }
const _hoisted_46 = { key: 0 }
const _hoisted_47 = { key: 1 }
const _hoisted_48 = { key: 2 }
const _hoisted_49 = { class: "flex gap-48" }
const _hoisted_50 = { class: "mg-b-10 fs-12" }
const _hoisted_51 = { class: "item-value" }
const _hoisted_52 = { key: 0 }
const _hoisted_53 = { class: "fc-primary mg-l-5 mg-r-5" }
const _hoisted_54 = { class: "button-box flex mg-t-16 flex-btetween" }
const _hoisted_55 = { class: "fc-grey flex-colum" }
const _hoisted_56 = { class: "fc-orange bord" }
const _hoisted_57 = {
  class: "mg-t-16 flex flex-ai-c",
  style: {"justify-content":"end"}
}
const _hoisted_58 = { class: "right" }
const _hoisted_59 = ["src"]
const _hoisted_60 = { class: "bold text-center mg-t-12" }
const _hoisted_61 = { class: "bold fs-12 fc-grey text-center mg-t-12" }
const _hoisted_62 = { class: "mg-t-30" }
const _hoisted_63 = { class: "flex flex-ai-c mg-b-12 fs-12" }
const _hoisted_64 = { class: "flex" }
const _hoisted_65 = { class: "flex" }
const _hoisted_66 = { class: "fc-primary" }
const _hoisted_67 = { class: "flex flex-jc-c" }
const _hoisted_68 = { key: 1 }
const _hoisted_69 = { class: "success-container" }
const _hoisted_70 = { class: "shadow-box success-box" }
const _hoisted_71 = { class: "flex flex-jc-sb flex-ai-end" }
const _hoisted_72 = { class: "flex-1" }
const _hoisted_73 = { class: "fs-12 mg-b-12" }
const _hoisted_74 = { class: "fs-12 mg-b-12" }
const _hoisted_75 = { key: 0 }
const _hoisted_76 = { key: 1 }
const _hoisted_77 = { key: 2 }
const _hoisted_78 = { class: "fs-12 mg-b-12" }
const _hoisted_79 = { key: 0 }
const _hoisted_80 = { class: "" }
const _hoisted_81 = { class: "popcontent" }
const _hoisted_82 = { class: "fc-orange bord" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Warning = _resolveComponent("Warning")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_uploadFile = _resolveComponent("uploadFile")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: "title fc-white fs-24",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isSuccess = !_ctx.isSuccess))
              }, " 充值中心 "),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", {
                  class: "icon pointer",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClose && _ctx.handleClose(...args)))
                }, " × ")
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              (!_ctx.isSuccess)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _cache[16] || (_cache[16] = _createElementVNode("div", { class: "bold mg-t-12 mg-b-22" }, "发薪权限", -1)),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_el_icon, { class: "fc-primary mg-r-5" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Select)
                          ]),
                          _: 1
                        }),
                        _cache[13] || (_cache[13] = _createElementVNode("span", null, "5个起发薪席位", -1))
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_el_icon, { class: "fc-primary mg-r-5" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Select)
                          ]),
                          _: 1
                        }),
                        _cache[14] || (_cache[14] = _createElementVNode("span", null, "无限创建发薪项目数", -1))
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_el_icon, { class: "fc-primary mg-r-5" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_Select)
                          ]),
                          _: 1
                        }),
                        _cache[15] || (_cache[15] = _createElementVNode("span", null, "无卡发薪", -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      (_ctx.orderInfo?.expireSeatsCount > 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createVNode(_component_el_icon, { class: "fc-orange mg-r-5" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Warning)
                              ]),
                              _: 1
                            }),
                            _cache[17] || (_cache[17] = _createElementVNode("span", null, "检测到您", -1)),
                            _createElementVNode("span", _hoisted_13, "有" + _toDisplayString(_ctx.orderInfo?.expireSeatsCount) + "个多出席位到期后将被停用，建议您另外购买，", 1),
                            _cache[18] || (_cache[18] = _createElementVNode("span", null, "已防为您的工作带来不便。", -1))
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["shadow-box box1", { 'selected': _ctx.isClick }])
                        }, [
                          _createElementVNode("div", {
                            class: "linear-box pointer",
                            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.changeClick && _ctx.changeClick(...args)))
                          }, [
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _cache[19] || (_cache[19] = _createTextVNode("1年")),
                                _createElementVNode("span", _hoisted_17, "¥" + _toDisplayString(_ctx.showPrice), 1)
                              ]),
                              _createElementVNode("div", {
                                class: _normalizeClass(["fc-orange fs-20 animation ff-dd bold", {'mg-t-5': !_ctx.isClick, 'mg-t-16': _ctx.isClick}])
                              }, [
                                _cache[20] || (_cache[20] = _createElementVNode("span", { class: "fs-14" }, "¥", -1)),
                                _createTextVNode(_toDisplayString(_ctx.price), 1),
                                _cache[21] || (_cache[21] = _createElementVNode("span", { class: "fs-14" }, "/年", -1))
                              ], 2),
                              _createElementVNode("div", _hoisted_18, "立省¥" + _toDisplayString(_ctx.showPrice - _ctx.price), 1)
                            ]),
                            _createElementVNode("div", _hoisted_19, [
                              _createVNode(_component_el_icon, { class: "fc-white fs-12 check-box" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Select)
                                ]),
                                _: 1
                              }),
                              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "triange-white" }, null, -1))
                            ]),
                            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "hot" }, [
                              _createElementVNode("img", {
                                src: _imports_0,
                                class: "icon-hot",
                                alt: ""
                              })
                            ], -1))
                          ])
                        ], 2),
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, [
                              _cache[26] || (_cache[26] = _createElementVNode("div", { class: "fs-12 bold mg-b-16" }, "另购席位数", -1)),
                              _createElementVNode("div", _hoisted_23, [
                                _createElementVNode("div", _hoisted_24, [
                                  _cache[24] || (_cache[24] = _createElementVNode("span", { class: "fs-14" }, "¥", -1)),
                                  _createTextVNode(_toDisplayString(_ctx.seatPrice), 1),
                                  _cache[25] || (_cache[25] = _createElementVNode("span", { class: "fs-14" }, "/位", -1))
                                ]),
                                _createVNode(_component_el_input_number, {
                                  modelValue: _ctx.num,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.num) = $event)),
                                  size: "small",
                                  disabled: !_ctx.canAddSeat,
                                  min: 0,
                                  onChange: _ctx.handleChange,
                                  onClick: _ctx.handleClick
                                }, null, 8, ["modelValue", "disabled", "onChange", "onClick"])
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_25, [
                            _createElementVNode("div", _hoisted_26, [
                              _createElementVNode("div", _hoisted_27, [
                                _createElementVNode("div", _hoisted_28, "当前已有席位数" + _toDisplayString(_ctx.orderInfo.hasSeat) + "（已用" + _toDisplayString(_ctx.orderInfo.useSeat) + "） ", 1),
                                _createVNode(_component_el_tooltip, {
                                  class: "box-item",
                                  effect: "dark",
                                  placement: "right"
                                }, {
                                  content: _withCtx(() => _cache[27] || (_cache[27] = [
                                    _createElementVNode("div", { class: "set-tooltip-width" }, " 产品包含5个发薪席位，超出部分的发薪席位需要另补差价，300元/位。到期未购买的席位，到期后将自动停用。 ", -1)
                                  ])),
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_icon, { class: "fc-orange mg-l-10" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_Warning)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _withDirectives(_createElementVNode("div", _hoisted_30, [
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("div", _hoisted_32, [
                              _createVNode(_component_uploadFile, {
                                "can-compress": "",
                                base64File: _ctx.fileUrl,
                                "onUpdate:base64File": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.fileUrl) = $event)),
                                "prop-name": "fileUrl",
                                limit: 1,
                                "limit-size": 10,
                                "remove-confirm": "",
                                onSuccess: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleSuccess($event))),
                                onRemove: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleRemove($event)))
                              }, {
                                default: _withCtx(() => _cache[28] || (_cache[28] = [
                                  _createElementVNode("span", { class: "tips" }, "添加付款凭证", -1)
                                ])),
                                _: 1
                              }, 8, ["base64File"])
                            ]),
                            (_ctx.bankInfo?.channelName==='苏州蓝豚')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                  _cache[30] || (_cache[30] = _createStaticVNode("<div class=\"mg-b-12 flex\" data-v-2015f4a2><span class=\"fc-grey name-item\" data-v-2015f4a2>企业全称：</span><span data-v-2015f4a2>苏州蓝豚互动信息科技有限公司</span></div><div class=\"mg-b-12 flex\" data-v-2015f4a2><span class=\"fc-grey name-item\" data-v-2015f4a2>银行账号：</span><span data-v-2015f4a2>554739755956</span></div><div class=\"mg-b-12 flex\" data-v-2015f4a2><span class=\"fc-grey name-item\" data-v-2015f4a2>开户行：</span><span data-v-2015f4a2>中国银行昆山城东支行</span></div>", 3)),
                                  _createElementVNode("div", _hoisted_34, [
                                    _cache[29] || (_cache[29] = _createElementVNode("span", { class: "fc-grey name-item" }, "付款金额：", -1)),
                                    _createElementVNode("span", _hoisted_35, "¥" + _toDisplayString(_ctx.totalMoney) + "元", 1)
                                  ])
                                ]))
                              : _createCommentVNode("", true),
                            (_ctx.bankInfo?.channelName==='重庆蓝豚')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                                  _cache[32] || (_cache[32] = _createStaticVNode("<div class=\"mg-b-12 flex\" data-v-2015f4a2><span class=\"fc-grey name-item\" data-v-2015f4a2>企业全称：</span><span data-v-2015f4a2>重庆蓝豚互动信息科技有限公司</span></div><div class=\"mg-b-12 flex\" data-v-2015f4a2><span class=\"fc-grey name-item\" data-v-2015f4a2>银行账号：</span><span data-v-2015f4a2>50050108400000001851</span></div><div class=\"mg-b-12 flex\" data-v-2015f4a2><span class=\"fc-grey name-item\" data-v-2015f4a2>开户行：</span><span data-v-2015f4a2>中国建设银行股份有限公司重庆渝北城南支行</span></div>", 3)),
                                  _createElementVNode("div", _hoisted_37, [
                                    _cache[31] || (_cache[31] = _createElementVNode("span", { class: "fc-grey name-item" }, "付款金额：", -1)),
                                    _createElementVNode("span", _hoisted_38, "¥" + _toDisplayString(_ctx.totalMoney) + "元", 1)
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ], 512), [
                          [_vShow, _ctx.curTab === 1]
                        ]),
                        _withDirectives(_createElementVNode("div", _hoisted_39, [
                          _createElementVNode("div", _hoisted_40, [
                            _cache[40] || (_cache[40] = _createElementVNode("div", { class: "name bold mg-b-10" }, "订单详情", -1)),
                            _createElementVNode("div", _hoisted_41, [
                              _createElementVNode("div", _hoisted_42, [
                                _cache[33] || (_cache[33] = _createElementVNode("span", { class: "item-name" }, "当前公司：", -1)),
                                _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.orderInfo.companyName), 1)
                              ]),
                              _createElementVNode("div", _hoisted_44, [
                                _cache[34] || (_cache[34] = _createElementVNode("span", { class: "item-name" }, "订单类型：", -1)),
                                _createElementVNode("span", _hoisted_45, [
                                  (_ctx.isClick)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_46, "续费"))
                                    : _createCommentVNode("", true),
                                  (_ctx.isClick && _ctx.num)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_47, "+"))
                                    : _createCommentVNode("", true),
                                  (_ctx.num)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_48, "席位"))
                                    : _createCommentVNode("", true)
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_49, [
                              _cache[39] || (_cache[39] = _createElementVNode("div", { class: "mg-b-10 fs-12 flex-base-300" }, [
                                _createElementVNode("span", { class: "item-name" }, "订购时长："),
                                _createElementVNode("span", { class: "item-value" }, "1年")
                              ], -1)),
                              _createElementVNode("div", _hoisted_50, [
                                _cache[38] || (_cache[38] = _createElementVNode("span", { class: "item-name" }, "发薪席位：", -1)),
                                _createElementVNode("span", _hoisted_51, [
                                  (_ctx.isClick)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_52, _cache[35] || (_cache[35] = [
                                        _createTextVNode("套餐包含"),
                                        _createElementVNode("span", { class: "fc-primary mg-l-5 mg-r-5" }, "5个", -1)
                                      ])))
                                    : _createCommentVNode("", true),
                                  _cache[36] || (_cache[36] = _createTextVNode(" 另购席位")),
                                  _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.num), 1),
                                  _cache[37] || (_cache[37] = _createTextVNode("个 "))
                                ])
                              ])
                            ]),
                            _cache[41] || (_cache[41] = _createElementVNode("div", { class: "fs-12" }, [
                              _createElementVNode("span", { class: "item-name" }, "预计生效时间："),
                              _createElementVNode("span", { class: "item-value" }, "付款审核成功后，立即生效")
                            ], -1))
                          ]),
                          _cache[42] || (_cache[42] = _createElementVNode("div", { class: "flex flex-ai-c mg-t-12" }, [
                            _createElementVNode("div", { class: "flex flex-ai-c mg-l-16" })
                          ], -1))
                        ], 512), [
                          [_vShow, _ctx.curTab === 2]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_54, [
                        _createElementVNode("div", _hoisted_55, [
                          _cache[43] || (_cache[43] = _createElementVNode("div", null, "订单总额(元)", -1)),
                          _createElementVNode("div", _hoisted_56, "¥" + _toDisplayString(_ctx.totalMoney) + "元", 1)
                        ]),
                        (_ctx.checked)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "bg-green pointer",
                              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.getRecharge && _ctx.getRecharge(...args)))
                            }, _cache[44] || (_cache[44] = [
                              _createElementVNode("img", {
                                src: _imports_1,
                                alt: "",
                                class: "img mg-r-5 w-h-24"
                              }, null, -1),
                              _createTextVNode(" 微信支付 ")
                            ])))
                          : _createCommentVNode("", true),
                        (!_ctx.checked)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: "bg-dark pointer",
                              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.getRechargeno && _ctx.getRechargeno(...args)))
                            }, _cache[45] || (_cache[45] = [
                              _createElementVNode("img", {
                                src: _imports_1,
                                alt: "",
                                class: "img mg-r-5 w-h-24"
                              }, null, -1),
                              _createTextVNode(" 微信支付 ")
                            ])))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_57, [
                        _createVNode(_component_el_checkbox, {
                          class: "mg-r-5",
                          modelValue: _ctx.checked,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.checked) = $event))
                        }, {
                          default: _withCtx(() => _cache[46] || (_cache[46] = [
                            _createTextVNode(" 我方已充分阅读并同意 ")
                          ])),
                          _: 1
                        }, 8, ["modelValue"]),
                        _createElementVNode("a", {
                          class: "fc-primary pointer",
                          onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.toService && _ctx.toService(...args)))
                        }, "《服务协议》"),
                        _createElementVNode("a", {
                          class: "fc-primary pointer",
                          onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.toPrivate && _ctx.toPrivate(...args)))
                        }, "《隐私协议》")
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_58, [
                      _createElementVNode("img", {
                        src: _ctx.avatar,
                        alt: "",
                        class: "icon-head"
                      }, null, 8, _hoisted_59),
                      _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.orderInfo.companyName), 1),
                      _createElementVNode("div", _hoisted_61, "团队账号到期时间：" + _toDisplayString(_ctx.orderInfo.accountExpireTime), 1),
                      _createElementVNode("div", _hoisted_62, [
                        _createElementVNode("div", _hoisted_63, [
                          _cache[47] || (_cache[47] = _createElementVNode("img", {
                            src: _imports_2,
                            alt: "",
                            class: "icon-seat mg-r-5"
                          }, null, -1)),
                          _createElementVNode("div", null, "当前共有席位数：" + _toDisplayString(_ctx.orderInfo.hasSeat), 1)
                        ]),
                        _createVNode(_component_el_scrollbar, { class: "set-height" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (i, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: "flex fs-12 flex-jc-sb mg-b-12"
                              }, [
                                _createElementVNode("div", _hoisted_64, [
                                  _createElementVNode("div", _hoisted_65, [
                                    _createTextVNode(_toDisplayString(_ctx.typeMap((i as any)?.salesScenario)) + " ：", 1),
                                    _createElementVNode("span", _hoisted_66, _toDisplayString((i as any).salesScenario === 1 ? 5 : (i as
                                                    any).buySeatCount), 1)
                                  ])
                                ]),
                                _createElementVNode("div", null, _toDisplayString(_ctx.formatDate((i as any)?.seatExpirationTime)) + "到期", 1)
                              ]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_67, [
                        _createVNode(_component_el_button, { onClick: _ctx.toNext }, {
                          default: _withCtx(() => _cache[48] || (_cache[48] = [
                            _createTextVNode("开通记录")
                          ])),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isSuccess)
                ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                    _createElementVNode("div", _hoisted_69, [
                      _cache[54] || (_cache[54] = _createElementVNode("img", {
                        src: _imports_3,
                        alt: "",
                        class: "icon-success"
                      }, null, -1)),
                      _cache[55] || (_cache[55] = _createElementVNode("div", { class: "hint text-center" }, [
                        _createTextVNode("我们将在1-2个工作日内确认支付结果。确认后，账户将自动完成续费。可留意您的专属客服群消息。也可在"),
                        _createElementVNode("span", { class: "fc-primary" }, "充值中心-团队账户"),
                        _createTextVNode("中查看订单状态")
                      ], -1)),
                      _createElementVNode("div", _hoisted_70, [
                        _createElementVNode("div", _hoisted_71, [
                          _createElementVNode("div", _hoisted_72, [
                            _cache[51] || (_cache[51] = _createElementVNode("div", { class: "fs-18 bold mg-b-24" }, "订单详情", -1)),
                            _createElementVNode("div", _hoisted_73, "当前公司：" + _toDisplayString(_ctx.orderInfo?.companyName), 1),
                            _createElementVNode("div", _hoisted_74, [
                              _cache[49] || (_cache[49] = _createTextVNode("订单类型： ")),
                              (_ctx.isClick)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_75, "续费"))
                                : _createCommentVNode("", true),
                              (_ctx.isClick && _ctx.num)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_76, "+"))
                                : _createCommentVNode("", true),
                              (_ctx.num)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_77, "席位"))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_78, [
                              _cache[50] || (_cache[50] = _createTextVNode("发薪席位：")),
                              (_ctx.isClick)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_79, "套餐包含 5 个"))
                                : _createCommentVNode("", true),
                              _createTextVNode(" 另购席位 " + _toDisplayString(_ctx.num) + " 个 ", 1)
                            ]),
                            _cache[52] || (_cache[52] = _createElementVNode("div", { class: "fs-12" }, "预计生效时间：付款审核成功后，立即生效", -1))
                          ]),
                          _createElementVNode("div", _hoisted_80, [
                            _createVNode(_component_el_button, {
                              type: "primary",
                              onClick: _ctx.toOrderList
                            }, {
                              default: _withCtx(() => _cache[53] || (_cache[53] = [
                                _createTextVNode("查看订单")
                              ])),
                              _: 1
                            }, 8, ["onClick"])
                          ])
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_dialog, {
      top: "20vh",
      modelValue: _ctx.isup,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.isup) = $event)),
      width: "450px",
      title: "微信扫码支付",
      center: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_81, [
          _cache[57] || (_cache[57] = _createElementVNode("div", {
            claas: "img-border",
            style: {"width":"200px","height":"200px","box-sizing":"border-box","border":"2px solid #F3F3F5","border-radius":"16px","display":"flex","align-items":"center","justify-content":"center"}
          }, [
            _createElementVNode("div", {
              id: "code",
              class: "code"
            })
          ], -1)),
          _createElementVNode("div", null, [
            _cache[56] || (_cache[56] = _createTextVNode("应付金额")),
            _createElementVNode("span", _hoisted_82, "¥" + _toDisplayString(_ctx.totalMoney) + "元", 1)
          ]),
          _cache[58] || (_cache[58] = _createElementVNode("div", null, [
            _createTextVNode("剩余支付时间"),
            _createElementVNode("span", { class: "fc-orange" }, "3分钟"),
            _createTextVNode("，否则订单将自动失效")
          ], -1))
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}