
import { defineComponent } from 'vue'

export default defineComponent({
    props: ['dataTitle', 'list', 'width', 'isCloseType'],
    methods: {
        close () {
            this.$emit('close')
            this.$Bus.emit('close2', '关闭了吗')
        }
    }
})
