import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "msg mg-b-16" }
const _hoisted_4 = { class: "flex flex-ai-c gap-10 fs-14 mg-b-16" }
const _hoisted_5 = { class: "fc-202536 flex flex-ai-c gap-10" }
const _hoisted_6 = { class: "flex flex-ai-c gap-10 fs-14 mg-b-16" }
const _hoisted_7 = { class: "fc-202536" }
const _hoisted_8 = { class: "flex flex-ai-c gap-10 fs-14" }
const _hoisted_9 = { class: "fc-202536" }

import { ElMessage } from 'element-plus'
import { ref, watch } from 'vue'
import clipboard from 'clipboard'
import { getCompanyInfoApi, CompanyInfoDto } from '@/api/alipay-recharge'
import user from '@/utils/user'

export default /*@__PURE__*/_defineComponent({
  __name: 'wechat-recharge-dialog',
  props: {
    show: {
        type: Boolean,
        default: false
    },
    num: {
        type: [String, Number],
        default: ''
    }
},
  emits: ['update:show'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const CompanyInfo = ref({} as CompanyInfoDto)
const showDialog = ref(false)
const copyText = () => {
    let text = `开户银行：财付通-备付金账户;银行账户：${CompanyInfo.value.merchantBankCard};开户行号：991584000049;户名：${user.companyName}`
    clipboard.copy(text)
    ElMessage.success('复制成功')
}

watch(() => props.show, (newVal) => {
    showDialog.value = newVal
    if (newVal) {
        getCompanyInfoApi().then((res) => {
            CompanyInfo.value = res
        })
    }
}, { immediate: true })

watch(showDialog, (newVal) => {
    emit('update:show', newVal)
})


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: showDialog.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showDialog).value = $event)),
      "show-close": true,
      width: "460px",
      title: "公对公充值",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (showDialog.value = false))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "fc-5E617D fs-14 mg-b-25" }, "商户转账充值专用银行账号 (一个商户号对应一个账号)", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex flex-ai-c flex-jc-sb w-80 fc-6B6B86" }, [
                _createElementVNode("div", null, "开"),
                _createElementVNode("div", null, "户"),
                _createElementVNode("div", null, "银"),
                _createElementVNode("div", null, "行：")
              ], -1)),
              _createElementVNode("div", _hoisted_5, [
                _cache[3] || (_cache[3] = _createElementVNode("div", null, "财付通-备付金账户", -1)),
                (CompanyInfo.value.merchantBankCard)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      size: "mini",
                      type: "primary",
                      onClick: copyText
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("一键复制")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "flex flex-ai-c flex-jc-sb w-80 fc-6B6B86" }, [
                _createElementVNode("div", null, "银"),
                _createElementVNode("div", null, "行"),
                _createElementVNode("div", null, "账"),
                _createElementVNode("div", null, "户：")
              ], -1)),
              _createElementVNode("div", _hoisted_7, _toDisplayString(CompanyInfo.value.merchantBankCard), 1)
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex flex-ai-c gap-10 fs-14 mg-b-16" }, [
              _createElementVNode("div", { class: "flex flex-ai-c flex-jc-sb w-80 fc-6B6B86" }, [
                _createElementVNode("div", null, "开"),
                _createElementVNode("div", null, "户"),
                _createElementVNode("div", null, "行"),
                _createElementVNode("div", null, "号：")
              ]),
              _createElementVNode("div", { class: "fc-202536" }, "991584000049")
            ], -1)),
            _createElementVNode("div", _hoisted_8, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "flex flex-ai-c flex-jc-sb w-80 fc-6B6B86" }, [
                _createElementVNode("div", null, "户"),
                _createElementVNode("div", null, "名：")
              ], -1)),
              _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(user).companyName), 1)
            ])
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "" }, [
            _createElementVNode("div", { class: "mg-b-10" }, [
              _createElementVNode("span", { class: "fc-3876F6 mg-r-6" }, "·"),
              _createTextVNode("本次转入最高限额为2000万，转账时请勿超限;")
            ]),
            _createElementVNode("div", { class: "mg-b-10" }, [
              _createElementVNode("span", { class: "fc-3876F6 mg-r-6" }, "·"),
              _createTextVNode("该账户只能通过央行大额支付系统转账，周一-周四关闭时间为17:15-20:30周五关闭时间为17:15-24:00节假日及大额支付系统关闭日不到账，其余时间可准实时到:; ")
            ]),
            _createElementVNode("div", { class: "mg-b-10" }, [
              _createElementVNode("span", { class: "fc-3876F6 mg-r-6" }, "·"),
              _createTextVNode("若转账金额大于5万，请在转账时选择“大额支付”、“加急”或\"实时”(各银行网银系统选择的不同) ")
            ]),
            _createElementVNode("div", { class: "mg-b-10" }, [
              _createElementVNode("span", { class: "fc-3876F6 mg-r-6" }, "·"),
              _createTextVNode("若你在柜面办理，请在转账时告知银行工作人员这笔转账的开户行行号为: 991584000049。")
            ])
          ], -1))
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})