import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Upload = _resolveComponent("Upload")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createBlock(_component_el_button, _mergeProps({
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.handleClick($event)), ["stop"])),
    type: _ctx.type
  }, _ctx.$attrs), {
    default: _withCtx(() => [
      (!_ctx.noIcon)
        ? (_openBlock(), _createBlock(_component_el_icon, {
            key: 0,
            class: "el-icon--right mg-r-5",
            style: {"vertical-align":"bottom"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Upload)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _cache[1] || (_cache[1] = _createTextVNode(" 导出 "))
      ])
    ]),
    _: 3
  }, 16, ["type"]))
}