<template>
    <div v-if="showDialog" class="festival-container">
        <div class="inner-box">
            <div class="close-box" @click="handleClose">
                <img src="../../assets/closetwo.png" alt="">
            </div>
            <div class="festival-box" @click="handleClose">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showDialog: false
        }
    },
    watch: {
        show: {
            handler(newval) {
                this.showDialog = newval
            },
            immediate: true
        },
        showDialog(newval) {
            this.$emit('update:show', newval)
        }
    },
    methods: {
        handleClose() {
            localStorage.setItem('2025qmPop', true)
            this.showDialog = false
        }
    }
}
</script>
<style lang="stylus" scoped>
.festival-container
    position fixed
    left 0
    top 0
    width 100vw
    height 100vh
    overflow auto
    background rgba(0, 0, 0, 0.7)
    z-index 100
    display flex
    align-items center
    justify-content center

.festival-box
    width 720px
    height 676px
    background url('../../assets/festival/qm-bg.png') no-repeat
    //background radial-gradient(103% 25% at 8% 0%, #F34B1F 0%, rgba(243, 75, 31, 0) 100%), linear-gradient(180deg, #D31213 0%, #CB0B0C 82%, #9B0100 100%)
    background-size 100% 100%
    border-radius 20px
    color #000
    margin-top -34px
    padding-top 160px
    padding-bottom 0
    box-sizing border-box

.festival-bg
    width 480px
    height 149px
    position absolute
    left 50%
    transform translateX(-50%)
    top -75px
    z-index 100

.festivel-content
    font-size 14px
    border-radius 16px
    padding 0 82px
    box-sizing border-box
    height 390px;

.lightblack
    color #3D3D3D

.yellow
    color #E20709

.btn-bg
    display inline-block
    height 32px
    color #E20709
    line-height 32px
    padding 0 15px 0 15px
    font-weight bold
    background-size 100% 100%
    background-repeat no-repeat
    border-radius 8px
    margin-top 10px

.hint
    text-align center
    color #FFFFFF
    width 100%
    margin-top 20px

.order
    width 20px
    height 20px
    line-height 20px
    border-radius 10px
    text-align center
    background rgba(255, 221, 165, .5)
    color #E20709
    margin-right 8px

.mt-6
    margin-top 6px

.mb-12
    margin-bottom 12px

.mb-6
    margin-bottom 6px

.mr-10
    margin-right 10px

.icon-close
    position absolute
    right 30px
    top -60px
    width 36px
    height 36px
    z-index 100

.close-box
    display flex
    align-items center
    justify-content flex-end
    width 100%
    margin-top 100px

.inner-box
    height 100vh
    min-height 800px
    min-width: 830px;
    overflow: auto;
    box-sizing: border-box;
    display flex
    align-items center
    justify-content safe center
    flex-direction column
    margin-bottom 10px;

.inner-box::-webkit-scrollbar {
    display: none;
}

</style>
