
import { defineComponent } from 'vue'
import store from '@/store/index'
export default defineComponent({
    props: ['dataTitle', 'list', 'width', 'isCloseType'],
    data () {
        return {
            includeList: [] as string[],
            $store: store
        }
    },
    watch: {
        async $route (to: {meta:{keepAlive: boolean}, path:string, name:string}) {
            // $store.state.tagsPages.map(item=>item.name)
            if (to.meta.keepAlive !== false && (this.includeList = store.state.tagsPages.map(item => item.name)).indexOf(to.name) === -1) {
                console.log(1);
                if (store.state.tagsPages.some((item: { path: string }) => item.path === to.path) && (to.meta.keepAlive === true || to.meta.keepAlive !== false)) { return }
                console.log(2);
                store.commit('addPage', this.$route)
            }
        }
    },
    methods: {
        close () {
            this.$emit('close')
        },
        delTab (page:string, name:string) {
            console.log(page, name, '1234556')
            store.commit('delPage', { page, name })
        },
        goTabPage (item: any) {
            console.log(121, item);
            this.$router.push({
                path: item.path
            })
        }
    }
})
