
import {defineComponent} from 'vue'
import {isWeixin} from "@/utils/util"
import {ElMessageBox} from "element-plus"
export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    noIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick(ev: any) {
      if (isWeixin()) {
        const earth = require('../assets/icon-earth.png')
        const more = require('../assets/icon-more.png')
        ElMessageBox.alert(
          `
                <div>
                    <span>微信浏览器暂不支持导出功能，请点击右上角的</span>
                    <img width="24" height="24" src="${earth}" alt="">
                    <span class="text">或点击</span>
                    <img width="24" height="24" src="${more}" alt="">
                    <span>选择‘使用默认浏览器打开’，在打开的浏览器中完成导出</span>
                </div>
              `,
          '提示',
          {
            dangerouslyUseHTMLString: true
          }
        )
      } else {
        this.$emit('export')
      }
    }
  }
})
