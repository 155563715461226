import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../assets/home/wechat.png'
import _imports_1 from '../assets/home/alipay.png'
import _imports_2 from '../assets/home/lock.png'
import _imports_3 from '@/assets/home/fast-pay.png'
import _imports_4 from '@/assets/home/rayroll.png'
import _imports_5 from '@/assets/home/staff.png'
import _imports_6 from '@/assets/home/sign-in.png'
import _imports_7 from '@/assets/chart/chart-line-green.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "welcome" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "welcome-text" }
const _hoisted_5 = { class: "el-dropdown-link fs-24 bold" }
const _hoisted_6 = { class: "flex mg-b-24 gap-20 mg-t-16" }
const _hoisted_7 = { class: "block w-45" }
const _hoisted_8 = { class: "block-header" }
const _hoisted_9 = {
  key: 0,
  class: "block-more point"
}
const _hoisted_10 = { class: "block-content" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "flex-1 p-r-24 border-r" }
const _hoisted_13 = {
  key: 0,
  class: "flex flex-btetween flex-ai-c"
}
const _hoisted_14 = { class: "balance" }
const _hoisted_15 = { class: "number" }
const _hoisted_16 = { class: "flex-1 p-l-40" }
const _hoisted_17 = {
  key: 0,
  class: "flex flex-btetween flex-ai-c"
}
const _hoisted_18 = { class: "balance" }
const _hoisted_19 = { class: "number" }
const _hoisted_20 = { class: "block flex-1" }
const _hoisted_21 = { class: "block-content" }
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "flex-1 border-r" }
const _hoisted_24 = { class: "balance" }
const _hoisted_25 = { class: "number" }
const _hoisted_26 = { class: "flex-1 p-l-40 border-r" }
const _hoisted_27 = { class: "balance" }
const _hoisted_28 = { class: "number" }
const _hoisted_29 = { class: "flex-1 p-l-40 border-r" }
const _hoisted_30 = { class: "balance" }
const _hoisted_31 = { class: "number" }
const _hoisted_32 = { class: "flex-1 p-l-40" }
const _hoisted_33 = { class: "balance" }
const _hoisted_34 = { class: "number" }
const _hoisted_35 = {
  key: 0,
  class: "basic block"
}
const _hoisted_36 = { class: "welcome" }
const _hoisted_37 = ["src"]
const _hoisted_38 = { class: "welcome-text" }
const _hoisted_39 = { class: "basic-data-list" }
const _hoisted_40 = { class: "basic-data" }
const _hoisted_41 = { class: "basic-data-num data-num" }
const _hoisted_42 = { class: "basic-data" }
const _hoisted_43 = { class: "basic-data-num data-num" }
const _hoisted_44 = { class: "basic-data" }
const _hoisted_45 = { class: "basic-data-num data-num" }
const _hoisted_46 = { class: "basic-data" }
const _hoisted_47 = { class: "basic-data-num data-num" }
const _hoisted_48 = { class: "commonly" }
const _hoisted_49 = { class: "trend block blur" }
const _hoisted_50 = {
  key: 0,
  class: "lock-box"
}
const _hoisted_51 = { class: "right" }
const _hoisted_52 = { class: "nav block" }
const _hoisted_53 = { class: "block-content" }
const _hoisted_54 = { class: "not-paid block" }
const _hoisted_55 = { class: "set-pd flex gap-16" }
const _hoisted_56 = { class: "basic-data-title" }
const _hoisted_57 = { class: "flex flex-jc-sb flex-ai-c" }
const _hoisted_58 = { class: "basic-data-num data-num" }
const _hoisted_59 = { class: "basic-data-title" }
const _hoisted_60 = { class: "flex flex-jc-sb flex-ai-c" }
const _hoisted_61 = { class: "basic-data-num data-num" }
const _hoisted_62 = { class: "workers block" }
const _hoisted_63 = { class: "block-header" }
const _hoisted_64 = { class: "block-content" }
const _hoisted_65 = { class: "basic-data-num data-num" }
const _hoisted_66 = { class: "risk-insure-box" }
const _hoisted_67 = { class: "insurance block blur risk-box" }
const _hoisted_68 = {
  key: 0,
  class: "lock-box"
}
const _hoisted_69 = { class: "row risk-info" }
const _hoisted_70 = { class: "num" }
const _hoisted_71 = { class: "num" }
const _hoisted_72 = { class: "num" }
const _hoisted_73 = { class: "num num-active" }
const _hoisted_74 = { class: "insurance block blur insure-box" }
const _hoisted_75 = {
  key: 0,
  class: "lock-box"
}
const _hoisted_76 = { class: "block-header" }
const _hoisted_77 = { class: "btn-insure-box" }
const _hoisted_78 = { class: "block-content" }
const _hoisted_79 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_down = _resolveComponent("arrow-down")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_trend = _resolveComponent("trend")!
  const _component_CaretRight = _resolveComponent("CaretRight")!
  const _component_tabCapsule = _resolveComponent("tabCapsule")!
  const _component_alipayRechargeDialog = _resolveComponent("alipayRechargeDialog")!
  const _component_wechatRechargeDialog = _resolveComponent("wechatRechargeDialog")!
  const _component_midAutumn = _resolveComponent("midAutumn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "welcome-avatar",
        src: _ctx.$store.state.avatar
      }, null, 8, _hoisted_3),
      _createElementVNode("span", _hoisted_4, [
        _createVNode(_component_el_dropdown, { trigger: "click" }, {
          dropdown: _withCtx(() => [
            _createVNode(_component_el_dropdown_menu, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyList, (item, i) => {
                  return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: i,
                    onClick: ($event: any) => (_ctx.onChangeCompany(item.companyId))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.companyName), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_5, [
              _createTextVNode(" 你好，" + _toDisplayString(_ctx.$store.state.companyName), 1),
              _createVNode(_component_el_icon, { class: "el-icon--right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_arrow_down)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "block-title" }, "账户余额", -1)),
          (_ctx.isOpenPaySalary)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", {
                  class: "flex flex-ai-c",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onDetail && _ctx.onDetail(...args)))
                }, _cache[14] || (_cache[14] = [
                  _createElementVNode("div", { class: "" }, "查看详情", -1),
                  _createElementVNode("div", { class: "triangle" }, null, -1)
                ]))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "key flex-ai-c flex gap-10" }, [
                _createElementVNode("img", {
                  class: "wh-16",
                  src: _imports_0,
                  alt: ""
                }),
                _createTextVNode(" 余额（元）")
              ], -1)),
              (_ctx.isOpenPaySalary)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _cache[16] || (_cache[16] = _createTextVNode("￥")),
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.accountProductStatus.pay.balance), 1)
                    ]),
                    _createVNode(_component_el_button, {
                      type: "primary",
                      plain: "",
                      size: "mini",
                      onClick: _ctx.onWechatRecharge
                    }, {
                      default: _withCtx(() => _cache[17] || (_cache[17] = [
                        _createTextVNode("充值")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[21] || (_cache[21] = _createElementVNode("div", { class: "key flex-ai-c flex gap-10" }, [
                _createElementVNode("img", {
                  class: "wh-16",
                  src: _imports_1,
                  alt: ""
                }),
                _createTextVNode(" 余额（元）")
              ], -1)),
              (_ctx.isOpenPaySalary)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _cache[19] || (_cache[19] = _createTextVNode("￥")),
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.accountProductStatus.alipay.balance), 1)
                    ]),
                    _createVNode(_component_el_button, {
                      type: "primary",
                      plain: "",
                      size: "mini",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShow = true))
                    }, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode("充值")
                      ])),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _cache[29] || (_cache[29] = _createElementVNode("div", { class: "block-header" }, [
          _createElementVNode("div", { class: "block-title" }, "账户余额"),
          _createElementVNode("div", { class: "block-more point" }, [
            _createElementVNode("div", { class: "flex flex-ai-c" })
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _cache[23] || (_cache[23] = _createElementVNode("div", { class: "key" }, "长期保余额>", -1)),
              _createElementVNode("div", _hoisted_24, [
                _cache[22] || (_cache[22] = _createTextVNode(" ￥")),
                _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.accountProductStatus.periodInsur.balance), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_26, [
              _cache[25] || (_cache[25] = _createElementVNode("div", { class: "key" }, "日日保余额>", -1)),
              _createElementVNode("div", _hoisted_27, [
                _cache[24] || (_cache[24] = _createTextVNode(" ￥")),
                _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.accountProductStatus.wesure.balance), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_29, [
              _cache[26] || (_cache[26] = _createElementVNode("div", { class: "key" }, "电子合同份数", -1)),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.accountProductStatus.eleSign.balance), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_32, [
              _cache[28] || (_cache[28] = _createElementVNode("div", { class: "key" }, "团意日保余额>", -1)),
              _createElementVNode("div", _hoisted_33, [
                _cache[27] || (_cache[27] = _createTextVNode(" ￥")),
                _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.accountProductStatus.tyxSign.balance), 1)
              ])
            ])
          ])
        ])
      ]),
      false
        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("img", {
                class: "welcome-avatar",
                src: _ctx.$store.state.avatar
              }, null, 8, _hoisted_37),
              _createElementVNode("span", _hoisted_38, " 你好，" + _toDisplayString(_ctx.$store.state.companyName), 1)
            ]),
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("div", null, [
                  _cache[30] || (_cache[30] = _createElementVNode("div", { class: "basic-data-title" }, "发薪账户余额", -1)),
                  _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.balances.payBalance), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("div", null, [
                  _cache[31] || (_cache[31] = _createElementVNode("div", { class: "basic-data-title" }, "可发金额", -1)),
                  _createElementVNode("div", _hoisted_43, _toDisplayString(_ctx.balances.canUseBalance), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_44, [
                _createElementVNode("div", null, [
                  _cache[32] || (_cache[32] = _createElementVNode("div", { class: "basic-data-title" }, "保险账户余额", -1)),
                  _createElementVNode("div", _hoisted_45, _toDisplayString(_ctx.balances.wesureBalance), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_46, [
                _createElementVNode("div", null, [
                  _cache[33] || (_cache[33] = _createElementVNode("div", { class: "basic-data-title" }, "合同账户余额", -1)),
                  _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.balances.eleSignOverAmount), 1)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_48, [
      _createElementVNode("div", _hoisted_49, [
        (!_ctx.isOpenPaySalary)
          ? (_openBlock(), _createElementBlock("div", _hoisted_50, _cache[34] || (_cache[34] = [
              _createElementVNode("img", {
                src: _imports_2,
                class: "lock-icon",
                alt: ""
              }, null, -1),
              _createElementVNode("p", null, [
                _createTextVNode("您还没有开通该功能权限"),
                _createElementVNode("br"),
                _createTextVNode("如有需要请联系销售")
              ], -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass({ lock: true, lockBlur: !_ctx.isOpenPaySalary })
        }, [
          _createVNode(_component_trend)
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_51, [
        _createElementVNode("div", _hoisted_52, [
          _cache[39] || (_cache[39] = _createElementVNode("div", { class: "block-header" }, [
            _createElementVNode("div", { class: "block-title" }, "快速开始 / 便捷导航")
          ], -1)),
          _createElementVNode("div", _hoisted_53, [
            _createElementVNode("div", {
              class: "fast-menu point",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (
                            _ctx.$router.push({
                                path: '/pay'
                            })
                            ))
            }, _cache[35] || (_cache[35] = [
              _createElementVNode("img", {
                class: "fast-menu-icon",
                src: _imports_3
              }, null, -1),
              _createElementVNode("div", null, "发薪查询", -1)
            ])),
            _createElementVNode("div", {
              class: "fast-menu point",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (
                            _ctx.$router.push({
                                path: '/payroll'
                            })
                            ))
            }, _cache[36] || (_cache[36] = [
              _createElementVNode("img", {
                class: "fast-menu-icon",
                src: _imports_4
              }, null, -1),
              _createElementVNode("div", null, "工资单查询", -1)
            ])),
            _createElementVNode("div", {
              class: "fast-menu point",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (
                            _ctx.$router.push({
                                path: '/book'
                            })
                            ))
            }, _cache[37] || (_cache[37] = [
              _createElementVNode("img", {
                class: "fast-menu-icon",
                src: _imports_5
              }, null, -1),
              _createElementVNode("div", null, "我的员工库", -1)
            ])),
            _createElementVNode("div", {
              class: "fast-menu point",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (
                            _ctx.$router.push({
                                path: '/sign'
                            })
                            ))
            }, _cache[38] || (_cache[38] = [
              _createElementVNode("img", {
                class: "fast-menu-icon",
                src: _imports_6
              }, null, -1),
              _createElementVNode("div", null, "查考勤", -1)
            ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_54, [
          _cache[44] || (_cache[44] = _createElementVNode("div", { class: "block-header" }, [
            _createElementVNode("div", { class: "block-title" }, "未领薪人员")
          ], -1)),
          _createElementVNode("div", _hoisted_55, [
            _createElementVNode("div", {
              class: "flex-no-shrink box flex-1 pointer",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$router.push({ path: '/pay', query: { type: 'pay', statusName: '未领取', auditStatus: 1 } })))
            }, [
              _createElementVNode("div", _hoisted_56, [
                _createElementVNode("div", _hoisted_57, [
                  _cache[40] || (_cache[40] = _createElementVNode("span", { class: "fs-16" }, "发薪未领取", -1)),
                  _createVNode(_component_el_icon, { style: {"vertical-align":"middle","color":"#1989fa"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CaretRight)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_58, [
                _createTextVNode(_toDisplayString(_ctx.unclaimedCount) + " ", 1),
                _cache[41] || (_cache[41] = _createElementVNode("span", { class: "fs-14" }, "人", -1))
              ])
            ]),
            _createElementVNode("div", {
              class: "flex-no-shrink box flex-1 pointer",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.push({ path: '/advance/applay', query: { type: 'not', status: 0, auditStatus: 1 } })))
            }, [
              _createElementVNode("div", _hoisted_59, [
                _createElementVNode("div", _hoisted_60, [
                  _cache[42] || (_cache[42] = _createElementVNode("span", { class: "fs-16" }, "预支未领取", -1)),
                  _createVNode(_component_el_icon, { style: {"vertical-align":"middle","color":"#1989fa"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CaretRight)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_61, [
                _createTextVNode(_toDisplayString(_ctx.advanceUnclaimedCount) + " ", 1),
                _cache[43] || (_cache[43] = _createElementVNode("span", { class: "fs-14" }, "人", -1))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_62, [
          _createElementVNode("div", _hoisted_63, [
            _cache[45] || (_cache[45] = _createElementVNode("div", { class: "block-title" }, "简历库", -1)),
            _createElementVNode("div", {
              class: "go-detail point",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$router.push({ path: '/book' })))
            }, [
              _createVNode(_component_el_icon, { style: {"vertical-align":"middle","color":"#1989fa"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_CaretRight)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_64, [
            _createElementVNode("div", null, [
              _cache[46] || (_cache[46] = _createElementVNode("div", { class: "basic-data-title" }, "人数", -1)),
              _createElementVNode("div", _hoisted_65, _toDisplayString(_ctx.addressPeopleCount), 1)
            ]),
            _cache[47] || (_cache[47] = _createElementVNode("img", {
              class: "commonly-right-img",
              src: _imports_7,
              alt: ""
            }, null, -1))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_66, [
      _createElementVNode("div", _hoisted_67, [
        (!_ctx.isOpenWesure)
          ? (_openBlock(), _createElementBlock("div", _hoisted_68, _cache[48] || (_cache[48] = [
              _createElementVNode("img", {
                src: _imports_2,
                class: "lock-icon",
                alt: ""
              }, null, -1),
              _createElementVNode("p", null, [
                _createTextVNode("您还没有开通该功能权限"),
                _createElementVNode("br"),
                _createTextVNode("如有需要请联系销售")
              ], -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass({ lock: true, lockBlur: !_ctx.isOpenWesure })
        }, [
          _cache[59] || (_cache[59] = _createElementVNode("div", { class: "block-header row" }, [
            _createElementVNode("div", { class: "block-title" }, "风险分析")
          ], -1)),
          _cache[60] || (_cache[60] = _createElementVNode("div", { class: "row" }, [
            _createElementVNode("div", {
              id: "risk",
              style: {"height":"350px","width":"100%"}
            })
          ], -1)),
          _createElementVNode("div", _hoisted_69, [
            _createElementVNode("div", null, [
              _cache[49] || (_cache[49] = _createElementVNode("div", { class: "title" }, "案件数", -1)),
              _createElementVNode("div", _hoisted_70, _toDisplayString(_ctx.riskInfo.caseAmount), 1)
            ]),
            _cache[56] || (_cache[56] = _createElementVNode("div", { class: "risk-adorn" }, null, -1)),
            _createElementVNode("div", null, [
              _cache[51] || (_cache[51] = _createElementVNode("div", { class: "title" }, "赔付金额", -1)),
              _createElementVNode("div", _hoisted_71, [
                _cache[50] || (_cache[50] = _createElementVNode("text", { class: "unit" }, "¥", -1)),
                _createTextVNode(_toDisplayString(_ctx.riskInfo.finalMoney), 1)
              ])
            ]),
            _cache[57] || (_cache[57] = _createElementVNode("div", { class: "risk-adorn" }, null, -1)),
            _createElementVNode("div", null, [
              _cache[53] || (_cache[53] = _createElementVNode("div", { class: "title" }, "赔付率", -1)),
              _createElementVNode("div", _hoisted_72, [
                _createTextVNode(_toDisplayString(Number(((_ctx.riskInfo.finalMoney / _ctx.riskInfo.totalPrice) * 100).toFixed(2))), 1),
                _cache[52] || (_cache[52] = _createElementVNode("text", { class: "unit" }, "%", -1))
              ])
            ]),
            _cache[58] || (_cache[58] = _createElementVNode("div", { class: "risk-adorn" }, null, -1)),
            _createElementVNode("div", null, [
              _cache[55] || (_cache[55] = _createElementVNode("div", { class: "title" }, "实时费率", -1)),
              _createElementVNode("div", _hoisted_73, [
                _createTextVNode("+" + _toDisplayString(_ctx.riskInfo.insurRate * 100), 1),
                _cache[54] || (_cache[54] = _createElementVNode("text", { class: "unit" }, "%", -1))
              ])
            ])
          ]),
          _cache[61] || (_cache[61] = _createElementVNode("div", { class: "insure-remind-box align-start" }, [
            _createElementVNode("div", { class: "title" }, " 建议 "),
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createElementVNode("text", null, " · 1-3类工种出险占比19.23%，需加强关注此职业类别用工单位 ")
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("text", null, " · 理赔率远高于平台平均水平，预计在下个调价周期进行价格上调 ")
              ])
            ])
          ], -1))
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_74, [
        (!_ctx.isOpenWesure)
          ? (_openBlock(), _createElementBlock("div", _hoisted_75, _cache[62] || (_cache[62] = [
              _createElementVNode("img", {
                src: _imports_2,
                class: "lock-icon",
                alt: ""
              }, null, -1),
              _createElementVNode("p", null, [
                _createTextVNode("您还没有开通该功能权限"),
                _createElementVNode("br"),
                _createTextVNode("如有需要请联系销售")
              ], -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass({ lock: true, lockBlur: !_ctx.isOpenWesure })
        }, [
          _createElementVNode("div", _hoisted_76, [
            _createElementVNode("div", _hoisted_77, [
              _createElementVNode("div", {
                class: _normalizeClass(['btn-insure', 'point', _ctx.recordNum === 'insure_type_rrb' ? 'btn-insure-active' : '']),
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onRecordNum('insure_type_rrb')))
              }, "日日保", 2),
              _createElementVNode("div", {
                class: _normalizeClass(['btn-insure', 'point', _ctx.recordNum === 'insure_type_tyrb' ? 'btn-insure-active' : '']),
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onRecordNum('insure_type_tyrb')))
              }, "团意保", 2)
            ]),
            _cache[63] || (_cache[63] = _createElementVNode("div", { class: "go-detail point" }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_78, [
            _createElementVNode("div", _hoisted_79, [
              _cache[64] || (_cache[64] = _createElementVNode("div", { class: "data-labels" }, [
                _createElementVNode("div", { class: "data-label blue" }, "在保人数")
              ], -1)),
              _createVNode(_component_tabCapsule, {
                list: ['今日', '近7天', '近30天'],
                active: _ctx.insuranceTab,
                onTabchange: _ctx.onInsuranceTab
              }, null, 8, ["active", "onTabchange"])
            ]),
            _cache[65] || (_cache[65] = _createElementVNode("div", { class: "row" }, [
              _createElementVNode("div", { id: "insurance" })
            ], -1))
          ])
        ], 2)
      ])
    ]),
    _createVNode(_component_alipayRechargeDialog, {
      show: _ctx.isShow,
      "onUpdate:show": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.isShow) = $event)),
      num: _ctx.accountProductStatus.alipay.balance
    }, null, 8, ["show", "num"]),
    _createVNode(_component_wechatRechargeDialog, {
      show: _ctx.isShowwechat,
      "onUpdate:show": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.isShowwechat) = $event))
    }, null, 8, ["show"]),
    _createVNode(_component_midAutumn, {
      show: _ctx.showFestival,
      "onUpdate:show": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.showFestival) = $event))
    }, null, 8, ["show"])
  ]))
}