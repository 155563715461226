import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../assets/img/bi/entry-logo.png'
import _imports_1 from '../assets/common/arrow.png'
import _imports_2 from '../assets/common/img-xisx.png'
import _imports_3 from '../assets/common/img-png.png'
import _imports_4 from '../assets/common/companyicon.png'
import _imports_5 from '../assets/common/success.png'
import _imports_6 from '../assets/common/err.png'
import _imports_7 from '../assets/long-insure/success.png'
import _imports_8 from '../assets/img/bi/gd.png'
import _imports_9 from '../assets/img/bi/gj.png'


const _hoisted_1 = { class: "container-header" }
const _hoisted_2 = { class: "container-title" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "user-box" }
const _hoisted_5 = { class: "parent" }
const _hoisted_6 = {
  key: 1,
  class: "hint-box"
}
const _hoisted_7 = { class: "center" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "center-i" }
const _hoisted_10 = { class: "align-items" }
const _hoisted_11 = {
  key: 0,
  src: _imports_2,
  class: "img",
  alt: ""
}
const _hoisted_12 = {
  key: 1,
  src: _imports_3,
  class: "img",
  alt: ""
}
const _hoisted_13 = { class: "state" }
const _hoisted_14 = { class: "w-330px" }
const _hoisted_15 = { class: "flex flex-center bg-blue" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "user-name" }
const _hoisted_18 = { class: "user-name" }
const _hoisted_19 = { class: "w-330px" }
const _hoisted_20 = { class: "company-list-box" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "flex gap-10 flex-ai-c" }
const _hoisted_23 = { class: "text-14" }
const _hoisted_24 = { class: "flex flex-ai-c gap-10" }
const _hoisted_25 = {
  key: 0,
  class: "tagstatus1"
}
const _hoisted_26 = {
  key: 1,
  class: "tagstatus2"
}
const _hoisted_27 = { class: "companysupname" }
const _hoisted_28 = {
  key: 0,
  src: _imports_7,
  class: "w-h-16",
  alt: ""
}
const _hoisted_29 = { class: "el-dropdown-link container-user" }
const _hoisted_30 = ["src"]
const _hoisted_31 = { class: "user-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_Close = _resolveComponent("Close")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_component_tabs = _resolveComponent("component-tabs")!
  const _component_dialogRecharge = _resolveComponent("dialogRecharge")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.titles, (item, index) => {
          return (_openBlock(), _createElementBlock("span", {
            key: index,
            class: _normalizeClass({
                'title-first': _ctx.$store.state.titles.length === 1,
                'title-last': _ctx.$store.state.titles.length > 1 && index != 0,
                title:
                    _ctx.$store.state.titles.length > 1 &&
                    index < _ctx.$store.state.titles.length - 1
            }),
            onClick: ($event: any) => (_ctx.goNav(index))
          }, _toDisplayString(item), 11, _hoisted_3))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_ctx.user.isPcAdmin)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "bi-button fs-16 flex flex-ai-c flex-jc-c mg-r-10 pointer",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toBI && _ctx.toBI(...args)))
              }, _cache[7] || (_cache[7] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "",
                  class: "entry-logo"
                }, null, -1),
                _createElementVNode("div", { class: "mg-l-5 fs-12 mg-t--5" }, "动态数据大屏", -1)
              ])))
            : _createCommentVNode("", true),
          (_ctx.showHint)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_el_carousel, {
                  class: "flex-1",
                  height: "30px",
                  "indicator-position": "none",
                  direction: "vertical",
                  "motion-blur": "",
                  autoplay: false,
                  interval: "2000"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hintList, (item) => {
                      return (_openBlock(), _createBlock(_component_el_carousel_item, {
                        class: "hint-item",
                        key: item
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, _toDisplayString(item), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_icon, {
                  class: "pointer",
                  onClick: _ctx.onCloseHint
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Close)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.isOpenPaySalary)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "cus-btn pointer mg-r-16",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = true))
            }, "产品续费"))
          : _createCommentVNode("", true),
        _createVNode(_component_el_popover, {
          placement: "bottom-end",
          visible: _ctx.isDownload,
          width: 400
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", {
              class: "download-center point fs-14",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onIsDownload && _ctx.onIsDownload(...args)))
            }, " 下载中心 ")
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                class: "top-center row-between",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (
                        _ctx.$router.push({
                            path: '/download'
                        })
                        ))
              }, _cache[8] || (_cache[8] = [
                _createElementVNode("div", { class: "fs-14" }, " 下载中心 ", -1),
                _createElementVNode("div", null, [
                  _createElementVNode("img", {
                    src: _imports_1,
                    class: "arrow-img",
                    alt: ""
                  })
                ], -1)
              ])),
              (_ctx.fileList.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_el_empty, { description: "暂无下载" })
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "list-center",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      (item.fileName.includes('.xlsx'))
                        ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                        : (_openBlock(), _createElementBlock("img", _hoisted_12)),
                      _createElementVNode("div", null, _toDisplayString(item.fileName), 1)
                    ]),
                    _createElementVNode("div", _hoisted_13, _toDisplayString(item.fileUrl ? '下载中' : '生成中'), 1)
                  ])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["visible"]),
        _createVNode(_component_el_popover, {
          placement: "bottom",
          width: 330,
          trigger: "click"
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("img", {
                class: "user-avatar",
                src: _ctx.$store.state.avatar
              }, null, 8, _hoisted_30),
              _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$store.state.nickName), 1),
              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "triangle" }, null, -1))
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("img", {
                  class: "user-avatar",
                  src: _ctx.$store.state.avatar
                }, null, 8, _hoisted_16),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$store.state.nickName), 1),
                  _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$store.state.companyName), 1)
                ])
              ]),
              _createVNode(_component_el_popover, {
                onShow: _ctx.onEnter,
                placement: "left",
                width: 330,
                trigger: "click"
              }, {
                reference: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createElementVNode("div", { class: "popflex" }, [
                    _createElementVNode("div", { class: "h-56" }, "切换企业"),
                    _createElementVNode("img", {
                      src: _imports_8,
                      alt: "",
                      class: "w-h-24"
                    })
                  ], -1)
                ])),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyList, (item, i) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "itembox",
                          key: i,
                          onClick: ($event: any) => (_ctx.sureLogin(item.companyId))
                        }, [
                          _createElementVNode("div", _hoisted_22, [
                            _cache[11] || (_cache[11] = _createElementVNode("img", {
                              src: _imports_4,
                              class: "w-h-32",
                              alt: ""
                            }, null, -1)),
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_23, _toDisplayString(item.companyName), 1),
                              _createElementVNode("div", _hoisted_24, [
                                (item.auditStatus)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, _cache[9] || (_cache[9] = [
                                      _createElementVNode("img", {
                                        src: _imports_5,
                                        class: "w-h-12",
                                        alt: ""
                                      }, null, -1),
                                      _createTextVNode("已认证 ")
                                    ])))
                                  : _createCommentVNode("", true),
                                (!item.auditStatus)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, _cache[10] || (_cache[10] = [
                                      _createElementVNode("img", {
                                        src: _imports_6,
                                        class: "w-h-12",
                                        alt: ""
                                      }, null, -1),
                                      _createTextVNode("未认证 ")
                                    ])))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_27, "主管理员：" + _toDisplayString(item.superAdminName), 1)
                              ])
                            ])
                          ]),
                          (item.isNowLogin)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_28))
                            : _createCommentVNode("", true)
                        ], 8, _hoisted_21))
                      }), 128))
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["onShow"]),
              _createElementVNode("div", {
                class: "popflex",
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.modifyCode && _ctx.modifyCode(...args)))
              }, _cache[13] || (_cache[13] = [
                _createElementVNode("div", { class: "h-56" }, "修改密码", -1),
                _createElementVNode("img", {
                  src: _imports_8,
                  alt: "",
                  class: "w-h-24"
                }, null, -1)
              ])),
              _createElementVNode("div", {
                class: "popflex",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.exit && _ctx.exit(...args)))
              }, _cache[14] || (_cache[14] = [
                _createElementVNode("div", { class: "h-56" }, "退出登录", -1),
                _createElementVNode("img", {
                  src: _imports_9,
                  alt: "",
                  class: "w-h-24"
                }, null, -1)
              ]))
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_component_tabs),
    _createVNode(_component_dialogRecharge, {
      show: _ctx.showDialog,
      "onUpdate:show": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showDialog) = $event)),
      onChangeTab: _ctx.handleChangeTab
    }, null, 8, ["show", "onChangeTab"])
  ], 64))
}