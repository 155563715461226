export default {
    token: ''
}

export const shortcuts = [
    {
        text: '今天',
        value: ():Date[] => {
            const start = new Date()
            return [start, start]
        }
    },
    {
        text: '最近7天',
        value: ():Date[] => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
        }
    },
    {
        text: '最近30天',
        value: ():Date[] => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
        }
    },
    {
        text: '最近90天',
        value: ():Date[] => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
        }
    }
]
