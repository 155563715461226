import {
    login,
    getUserInfo,
    GetUserInfoResDto,
    getPhoto,
    GetPhotoResDto,
    getTokenAsCode
} from '../api/base'
import global from './global'
import store from '@/store'
import router from '@/router'
import { replaceCompany } from '@/api/alipay-recharge'

export interface UserDto {
    userId: string
    userName: string
    nickName: string
    phone: string
    sex: '0' | '1'
    avatar: string
    companyId: string
    isAdmin: boolean
    isPcAdmin: boolean
    isGsc: boolean
    isOpenEleSign: boolean
    isOpenPaySalary: boolean
    isOpenWesure: boolean
    isWaiting: boolean
    accountExpireInfo: any
}

class User {
    auditStatus = false
    isForbidInsure = false
    userId = 0
    userName = ''
    nickName = ''
    phone = ''
    sex = ''
    avatar = ''
    companyId = ''
    isAdmin = false
    isPcAdmin = false
    isWaiting = false
    companyName = ''
    isGsc = false
    isOpenEleSign = false
    isOpenPaySalary = false
    isOpenWesure = false
    accountExpireInfoStatus = 0
    wechatMchId = ''
    receiptId = 0
    bankName = ''
    bankNo = ''
    openBank = ''

    waitLogin(): Promise<number> {
        return new Promise(resolve => {
            setInterval(() => {
                if (this.isWaiting) {
                    resolve(this.userId)
                }
            }, 300)
            if (this.isWaiting) {
                resolve(this.userId)
            }
        })
    }

    getPhoto(): Promise<GetPhotoResDto> {
        return getPhoto()
    }

    exit() {
        this.userId = 0
        global.token = ''
        store.state.tagsPages = []
        // sessionStorage.removeItem('token')
        // sessionStorage.removeItem('closeExpireDialog')
        // sessionStorage.removeItem('festivalClose')
        // sessionStorage.removeItem('festivalClose')
        sessionStorage.clear()
        store.commit('stopFileTimer')
        store.state.fileList = []
        store.state.intervalId = ''
        router.push({
            path: '/login'
        })
    }

    async login(data: {
        username: string
        password: string
        code: string
        uuid: string
    }) {
        await login(data)
    }

    async switchLogin(data: {
        username: string | number,
        companyId: number | string
    }): Promise<GetUserInfoResDto> {
        const result = await replaceCompany(data)
        global.token = result.token
        sessionStorage.token = result.token
        return this.getUserInfo()
    }

    async getUserInfo() {
        const data: any = await getUserInfo()
        console.log(data, 'userinfouserinfouserinfouserinfo')
        this.auditStatus = data.user.auditStatus
        this.isForbidInsure = data.isForbidInsure
        this.userId = data.user.userId
        this.phone = data.user.phonenumber
        this.nickName = data.user.nickName
        this.userName = data.user.userName
        this.sex = data.user.sex
        this.isAdmin = data.user.admin
        this.isPcAdmin = data.user.isPcAdmin
        this.avatar = data.user.avatar
        this.companyName = data.user.companyName
        this.isGsc = data.isGsc
        this.isOpenEleSign = data.isOpenEleSign
        this.isOpenPaySalary = data.isOpenPaySalary
        this.isOpenWesure = data.isOpenWesure
        this.isWaiting = true
        this.accountExpireInfoStatus = data.accountExpireInfo.status
        this.wechatMchId = data.bankInfo.wechatMchId
        this.receiptId = data.bankInfo.receiptId
        this.bankName = data.bankInfo.bankName
        this.bankNo = data.bankInfo.bankNo
        this.openBank = data.bankInfo.openBank
        console.log(this.openBank, ' this.openBank')
        store.commit('setUser', {
            avatar: data.user.avatar,
            nickName: data.user.nickName,
            companyName: data.user.companyName,
            auditStatus: data.user.auditStatus,
        })
        store.commit('setBankInfo', data.bankInfo)
        return data
    }

    async codeLogin(code: string) {
        return (await getTokenAsCode({
            code
        })).companyList
        // global.token = result.token
        // sessionStorage.token = result.token
        // return this.getUserInfo()
    }
}

export default new User()
