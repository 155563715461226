import request from './request'
import { QueryPageDto, QueryDateSlotDto } from './dto'
// import { Key } from 'element-plus'
// 导出项目人员列表
export function exportProjectWorkers(params: { ids: string, serveCompanyId?: number | string }): Promise<string> {
    return request.get('/rest/pay/payWorkerProjectRecord/export', {
        params: params
    })
}

// 导出合同记录
export function exportContractRecord(params: { ids: string, searchText?: string }, config: any): Promise<{
    id: number, fileUrl: string, exportStatus: any, exportType
    : number, updateTime: string, remark: any
}> {
    return request.get('/ele-module-export/ele-sign-contract-record', {
        params: params,
        headers: config
    })
}

// 导出员工领薪明细
export function exportWorkerPayRecord(params: { userId: string, queryMonth: string }): Promise<string> {
    return request.get('/rest/pay/address-book/export', {
        params: params
    })
}

// 导出对账单 1111
export function exportBill(params: { serveCompanyName: string, startTime: string, endTime: string }, config: any): Promise<{
    type: any, id: number, fileUrl: string, exportStatus: any, exportType
    : number, updateTime: string, remark: any
}> {
    return request.get('/pay-module-export/statistics-info-all', {
        params: params,
        headers: config
    })
}

// 导出考勤表
interface exportSignDto extends QueryPageDto, QueryDateSlotDto {
    projectId: string
    serveCompanyId: string,
    userId: string
    queryType?: number
}
export function exportSign(params: exportSignDto): Promise<string> {
    return request.get('/rest/pay/blueSign/sign-in-record-export', {
        params: params
    })
}
// 导出竖版表格 /rest/pay/blueSign/export-sign-data
export function exportSignVertical(params: exportSignDto): Promise<string> {
    return request.get('/rest/pay/blueSign/export-sign-data', {
        params: params
    })
}
// 导出对账详单
export function exportBillDetail(params: { serveCompanyId: string, startTime: string, endTime: string, queryStatus: string | null }): Promise<string> {
    return request.get('/rest/pay/pay-payment-record/statistics-info-export', {
        params: params
    })
}

// 导出工资单勾选
export function exportPayroll(params: { ids: string, searchText: string }, config: any): Promise<{
    type: any, id: number, fileUrl: string, exportStatus: any, exportType
    : number, updateTime: string, remark: any
}> {
    return request.get('/pay-module-export/pay-stub/export', {
        params: params,
        headers: config
    })
}
// 导出勾选发薪单
interface exportProcessOrderDto extends QueryDateSlotDto {
    remarks: string
    searchText: string
    ids: string,
    createUserName?: string
}
export function exportProcessOrder(params: exportProcessOrderDto, config: any): Promise<{
    id: number, fileUrl: string, exportStatus: any, exportType
    : number, updateTime: string, remark: any
}> {
    return request.get('/pay-module-export/processOrder/export', {
        params: params,
        headers: config
    })
}
// /rest/pay/pay-payment-record/export-by-process-ids
export function exportProcessDetailOrder(params: string, config: any): Promise<{
    id: number, fileUrl: string, exportStatus: any, exportType
    : number, updateTime: string, remark: any, beginPayTime: string, endPayTime: string

}> {
    return request.get('/pay-module-export/salaried-detailed-by-process-ids?processIds=' + params, {
        headers: config
    })
}

// /rest/pay/pay-payment-record/export-by-process-ids
export function exportProcessDetailOrderNew(params: any, config: any): Promise<{
    id: number, fileUrl: string, exportStatus: any, exportType
    : number, updateTime: string, remark: any, beginPayTime: string, endPayTime: string

}> {
    return request.get('/pay-module-export/salaried-detailed-by-process-ids', {
        params: params,
        headers: config
    })
}

// 导出工资单详情勾选择
export function exportPayrollDetail(params: { ids: string, searchText: string, stubId: string | string[] }): Promise<string> {
    return request.get('/rest/pay-stub-detail/export', {
        params: params
    })
}

// 导出发薪明细
export function exportProcessDeatil(params: { processId: string, searchText: string, ids: string }): Promise<string> {
    return request.get('/rest/pay/pay-payment-record/export', {
        params: params
    })
}
interface PayrollRecordExportDto {
    beginPayTime: string,
    endPayTime: string,
    beginGetSalaryTime: string,
    endGetSalaryTime: string,
    ids: string,
    searchText: string,
    serveCompanyId: string,
    status: string | number
}
// 领薪记录导出 /rest/pay/pay-payment-record/export payroll-record
export function payrollRecordExport(params: PayrollRecordExportDto, config: any): Promise<{
    id: number, fileUrl: string, exportStatus: any, exportType
    : number, updateTime: string, remark: any
}> {
    return request.get('/pay-module-export/pay-payment-record', {
        params: params,
        headers: config
    })
}

// 个人领薪记录导出
export function payrollRecordOwnExport(params: any, config: any): Promise<{
    id: number, fileUrl: string, exportStatus: any, exportType
    : number, updateTime: string, remark: any
}> {
    return request.get('/pay-module-export/pay-personal-paid', {
        params: params,
        headers: config
    })
}

// 投保扣费记录导出
export function insureDeductionExport(params: { beginCreateTime: string, endCreateTime: string, searchText: string, insureCompanyType: string }, config: any): Promise<{
    id: number, fileUrl: string, exportStatus: any, exportType
    : number, updateTime: string, remark: any
}> {
    return request.get('/insure-module-export/insure-info', {
        params: params,
        headers: config
    })
}
// 投保扣费单条记录导出
export function insureDeductionDetailExport(params: { ids: string | string[] }): Promise<string> {
    return request.get('/rest/insure/insure-info/export-detail', {
        params: params
    })
}

// 项目归档导出  /rest/pay/payWorkerProjectRecord/archived-export
export function regressionFileExport(params: { ids: string | string[] }, config: any): Promise<{
    id: number, fileUrl: string, exportStatus: any, exportType
    : number, updateTime: string, remark: any
}> {
    return request.get('/pay-module-export/archived-export', {
        params: params,
        headers: config
    })
}

// 充值记录导出
export function rechargeRecordExport(params: { exportType: string, beginCreateTime: string, endCreateTime: string }, config: any): Promise<{
    id: number, fileUrl: string, exportStatus: any, exportType
    : number, updateTime: string, remark: any
}> {
    return request.get('/pay-module-export/recharge-record', {
        params: params,
        headers: config
    })
}

// 导出保险扣费记录明细
export function insuranceDetailedExport(params: { searchText: string, beginCreateTime: string, endCreateTime: string, insureCompanyType: string }, config: any): Promise<{
    id: number, fileUrl: string, exportStatus: any, exportType
    : number, updateTime: string, remark: any
}> {
    return request.get('/insure-module-export/export-by-condition', {
        params: params,
        headers: config
    })
}

// 下载记录列表
export function downloadHistoryList(data: {
    pageSize: number,
    pageNum: number
}): Promise<any> {
    return request.get('/file/log/list', {
        params: data
    })
}

// 轮询 检测是否有在导出的数据
export function longPollingFile(data: {
    ids: any
}, config: any): Promise<any> {
    return request.get('/file/log', {
        params: data,
        headers: config
    })
}

// 修改导出列表数据
export function updateExportStatus(data: any, config: any): Promise<void> {
    return request.put('/file/log/status', data, {
        headers: config
    })
}

// 导出长期保 在保人员名单列表
export function updateLongEnsureWorker(data: any, config: any): Promise<any> {
    return request.get('/insure-module-export/list-insure-effective-user-export', {
        params: data,
        headers: config
    })
}

// 导出日日保 在保人员名单列表
export function updateEnsureWorker(data: any, config: any): Promise<any> {
    return request.get('/insure-module-export/list-wesure-effective-user-export', {
        params: data,
        headers: config
    })
}

// 导出日日保 在保人员名单列表
export function downloadSignRecordPdfForFlows(data: any, config:any): Promise<any> {
    return request.post('/rest/elesign/ele-sign-contract-record/download-sign-record-pdf-for-flows', data, { headers: config })
}
