import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../assets/home/alipay.png'


const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "mg-b-25 fs-14 fc-9497B1" }
const _hoisted_4 = { class: "flex flex-ai-c gap-20" }

import { alipayRecharge } from '@/api/alipay-recharge'
import { ElMessage } from 'element-plus'
import { ref, watch } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'alipay-recharge-dialog',
  props: {
    show: {
        type: Boolean,
        default: false
    },
    num: {
        type: [String, Number],
        default: ''
    }
},
  emits: ['update:show'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const value = ref('')
const showDialog = ref(false)
const onKeyUp = () => {
    // 使用正则表达式来验证输入值
    const regex = /^(\d+(\.\d{1,2})?|\.\d{1,2})$/
    if (!regex.test(value.value)) {
        // 如果输入不符合规则，则去除不符合规则的部分
        value.value = value.value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
        const parts = value.value.split('.')
        if (parts[1] && parts[1].length > 2) {
            value.value = parts[0] + '.' + parts[1].slice(0, 2)
        }
        if (value.value.startsWith('.')) {
            value.value = '0' + value.value
        }
        if (parseFloat(value.value) < 0) {
            value.value = '0' // 如果输入为负数，则重置为0
        }
    }
}
const onSubmit = async () => {
    if (value.value !== '') {
        showDialog.value = false
        let num = value.value.endsWith('.') ? value.value + '00' : value.value
        let res = await alipayRecharge({ totalAmount: num })
        if (res) {
            window.open(res.body)
        }
    } else {
        ElMessage.error('请输入充值金额')
    }
}
watch(() => props.show, (newVal) => {
    value.value = ''
    showDialog.value = newVal
}, { immediate: true })

watch(showDialog, (newVal) => {
    emit('update:show', newVal)
})


return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: showDialog.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((showDialog).value = $event)),
      "show-close": true,
      width: "500px",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      onClose: _cache[2] || (_cache[2] = ($event: any) => (showDialog.value = false))
    }, {
      title: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("div", { class: "title" }, [
          _createElementVNode("img", {
            class: "icon",
            src: _imports_0,
            alt: ""
          }),
          _createTextVNode(" 支付宝账户充值 ")
        ], -1)
      ])),
      footer: _withCtx(() => [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: onSubmit
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("立即充值")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, "当前账户可用金额￥" + _toDisplayString(__props.num), 1),
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "fs-22 bold" }, "¥", -1)),
            _withDirectives(_createElementVNode("input", {
              class: "flex-1 border-none",
              onInput: onKeyUp,
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
              placeholder: "请输入充值金额"
            }, null, 544), [
              [_vModelText, value.value]
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})