import request from './request'
import { QueryPageDto, QueryDateSlotDto } from './dto'

export interface EnterpriseDto{
    contacts: string
    ppCount: number
    progressCount: number
    pwCount: number
    serveCompanyName: string,
    id:string
}

interface GetEnterpriseListResDto{
    list: EnterpriseDto[]
    total: number
}
// 获取派遣单位列表
export function getEnterpriseList (data: QueryPageDto): Promise<GetEnterpriseListResDto> {
    return request.get('/rest/pay/serve-company/list', {
        params: data
    })
}

interface GetSignProjectListDto extends QueryPageDto, QueryDateSlotDto {}

// 获取考勤里的派遣单位列表
export function getSignEnterpriseList (data:GetSignProjectListDto): Promise<GetEnterpriseListResDto> {
    return request.get('/rest/pay/blueSign/sign-info-serve-company', {
        params: data
    })
}
// 获取考勤里的个人列表
export function getSignAloneList (data:GetSignProjectListDto): Promise<GetEnterpriseListResDto> {
    return request.get('/rest/pay/blueSign/sign-info-user', {
        params: data
    })
}

// 查询公司下派遣单位列表
export function getEnterpriseNameList ():Promise<GetEnterpriseListResDto> {
    return request.get('/rest/pay/serve-company/list-to-pay')
}

// 查询公司下驻场列表
export function getResidentNameList ():Promise<{userName:string, userId:string, phone:string}[]> {
    return request.get('/rest/pay/processOrder/linkman')
}

interface AllEnterpriseListDto extends QueryPageDto {
    serveCompanyName:string
}
// 全部企业列表接口
export function getAllEnterpriseList (data:AllEnterpriseListDto):Promise<any> {
    return request.get('/rest/company/query-serve-company', {
        params: data
    })
}

// 删除企业
export function deleteCompany (data:any): Promise<void> {
    return request.put('/admin/company/delete', data)
}

// 编辑企业
export function editCompany (data:any): Promise<void> {
    return request.put('/admin/company/edit-server-company', data)
}

// 查看企业的预支单数
export function serveCompanyAdvanceCount (data:any): Promise<void> {
    return request.get('/rest/pay/advance/apply/serve-company-advance-count', {
        params: data
    })
}
