
    import QRCode from "qrcodejs2";
    import user from "@/utils/user";
    import { defineComponent } from "vue";
    import uploadFile from "@/components/upload-file.vue";
    import store from "@/store/index";
    import { getCompanySeatInfo, addRechargeRecord } from "@/api/recharge-record";
    import dayjs from "dayjs";
    import { ElMessage } from "element-plus";
    import { recharge } from "@/api/home";
    export default defineComponent({
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            noNeedRouter: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            uploadFile,
        },
        data() {
            return {
                isup: false,
                qrcode: "" as any,
                window: window as any,
                visible: false,
                num: 0,
                curTab: 2,
                checked: false,
                isClick: false,
                fileUrl: "",
                list: [],
                orderInfo: {} as any,
                isSuccess: false,
                price: 3599,
                showPrice: 6000,
                seatPrice: 300,
            };
        },
        watch: {
            show: {
                handler(newval) {
                    this.visible = newval;
                    if (newval) {
                        this.getData();
                    }
                },
                immediate: true,
            },
            visible(newval) {
                this.$emit("update:show", newval);
                if (!newval) {
                    this.init();
                    this.isSuccess = false;
                }
            },
        },
        computed: {
            totalMoney() {
                return this.isClick
                    ? this.price + this.seatPrice * (this.num ?? 0)
                    : this.seatPrice * (this.num ?? 0);
            },
            canButtonClick() {
                return !!this.fileUrl && this.checked && (this.isClick || this.num);
            },
            avatar() {
                return store.state.avatar;
            },
            bankInfo() {
                return (store.state.bankInfo || {}) as any;
            },
            canAddSeat() {
                return (
                    this.orderInfo.accountExpireTime >=
                        dayjs().format("YYYY-MM-DD") || this.isClick
                );
            },
            // willExpireSeatNumber() {
            //     let list = (this.orderInfo.seatList || []) as any
            //     const res = list.filter((i: any) => (dayjs(i.dueDate) < dayjs(expireDay)) && dayjs(i.dueDate) > dayjs(curDay))
            //     return res.length
            // },
            typeMap() {
                return (type: any) => {
                    const map = {
                        1: "赠送席位",
                        2: "席位购买",
                    };
                    return (map as any)[type];
                };
            },
            formatDate() {
                return (date: any) => {
                    return dayjs(date).format("YYYY-MM-DD");
                };
            },
        },
        emits: ["refresh", "changeTab", "update:show"],
        methods: {
            generateQRCode(url: { codeUrl: string }) {
                this.window.document.getElementById("code").innerHTML = "";
                this.qrcode = new QRCode("code", {
                    text: url.codeUrl, // 二维码所携带的数据
                    width: 170, // 二维码宽度
                    height: 170, // 二维码高度
                });
            },
            getRechargeno() {
                ElMessage({
                    type: "warning",
                    message: "是否确认协议",
                });
            },
            async getRecharge() {
                if (this.num === 0 && !this.isClick) {
                    ElMessage({
                        type: "warning",
                        message: "请选择产品",
                    });
                    return;
                }
                this.isup = true;
                setTimeout(() => {
                    this.isup = false;
                }, 180000);
                const result = await recharge({
                    amount: { total: this.totalMoney },
                    total: this.totalMoney,
                    description: "发薪续费",
                    mchid: 1673440814,
                    useWay: "AccountRecharge",
                    attach: `{month: ${this.isClick ? 12 : 0},paySeat: ${this.num},salesScenario:${this.isClick && this.num !== 0? 3 : !this.isClick && this.num !== 0 ? 2 : 1}}`,
                });
                await this.generateQRCode(result);
            },
            changeClick() {
                this.isClick = !this.isClick;
                if (
                    this.orderInfo.accountExpireTime <
                        dayjs().format("YYYY-MM-DD") &&
                    !this.isClick
                ) {
                    this.num = 0;
                }
            },
            handleClose() {
                this.visible = false;
            },
            handleChange() {
                console.log(11111);
            },
            changeTab(tab: number) {
                this.curTab = tab;
            },
            getData() {
                getCompanySeatInfo({
                    pageNum: 1,
                    pageSize: 10,
                }).then((res: any) => {
                    this.orderInfo = res || {};
                    sessionStorage.setItem(
                        "accountExpireTime",
                        res.accountExpireTime
                    );
                    this.list = res?.seatList.list || [];
                });
            },
            handleSuccess(ev: string) {
                console.log(111, ev);
            },
            handleRemove(ev: string) {
                console.log(222, ev);
            },
            toNext() {
                if (!this.noNeedRouter) {
                    this.$router.push({
                        path: "/recharge/record",
                        query: { tab: "group" },
                    });
                } else {
                    this.$emit("changeTab", "group");
                }
                store.commit("changeRechargeTab", "group-" + new Date().getTime());
                this.visible = false;
            },
            submit() {
                let remark = [];
                let salesScenario = null;
                if (this.isClick) {
                    salesScenario = 1;
                    remark.push("发薪专户续费");
                }
                if (this.num) {
                    salesScenario = 2;
                    remark.push(`另购席位${this.num}个`);
                }
                if (this.isClick && this.num) {
                    salesScenario = 3;
                }
                const query = {
                    payMoney: this.totalMoney,
                    rechargeAmount: 12,
                    voucherUrl: this.fileUrl,
                    receiptId: this.bankInfo.receiptId,
                    buySeatCount: this.num || 0,
                    remark: remark.join(" + "),
                    salesScenario: salesScenario,
                    payMode: this.curTab,
                };
                addRechargeRecord(query).then((res: any) => {
                    // this.visible = false
                    this.isSuccess = true;
                    this.$emit("refresh");
                });
            },
            init() {
                this.num = 0;
                this.isClick = false;
                this.checked = false;
            },
            toService() {
                window.open(location.origin + "/#/agreement/serviceAgreement");
            },
            toPrivate() {
                window.open(location.origin + "/#/agreement/privateAgreement");
            },
            toOrderList() {
                this.visible = false;
                this.$router.push({
                    path: "/recharge/record",
                    query: {
                        tab: "group",
                    },
                });
            },
            handleClick() {
                if (
                    this.orderInfo.accountExpireTime <
                        dayjs().format("YYYY-MM-DD") &&
                    !this.isClick
                ) {
                    ElMessage.error(
                        "您的产品已经到期，续费后即可继续使用并购买席位"
                    );
                }
            },
        },
    });
